<block-ui>
    <div class="container bootstrap snippets bootdey">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-md-offset-3">
                <img src="../../assets/img/logo-icon.png" class="logo" alt="" style="width: 100px;">
                <div class="account-wall accountchooser">
                    <h1 class="title">
                        Elige Empresa</h1>
                    <div *ngFor="let tipos of data" style="margin-bottom: 10px;">
                        <ol class="accounts">
                            <li>
                                <div class="empresa">
                                    <!-- <img class="account-image" alt=""
                                    src="https://bootdey.com/img/Content/avatar/avatar1.png" /> -->
                                    <span class="account-name" (click)="btn_enviar(tipos.base)">{{tipos.base}}</span>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</block-ui>