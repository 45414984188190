<section>
    <block-ui>
    </block-ui>
    <div class="row mt-4">
        <div class="col-12 col-lg-12">
            <div class="row">
                <div class="col-sm-6" style="font-size: 22px;">
                    <span *ngIf="this.info.estatus == 22">
                        <i class="fa-solid fa-circle-info"></i> Una vez terminado de capturar precio, fechas de entrega
                        y subir archivos, favor de dar clic en el boton <strong>"COTIZADO"</strong>
                    </span>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <button *ngIf="this.info.estatus == 22" class="btn btn-outline-success" (click)="btnConfirmado()">COTIZADO</button>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>Solicitud Cotizacion</strong>
                            <div>Folio: {{this.info.folio}} </div>
                            <div>Fecha: {{this.info.fecha | date:'dd/MM/yyyy'}}
                            </div>
                            <div>Entrega: {{this.info.fecha_entrega | date:'dd/MM/yyyy'}}
                            </div>
                            <div>
                                <div *ngIf="this.info.estatus == 50; then thenBlock else elseBlock"></div>
                                <ng-template #thenBlock>
                                    Estatus: {{this.info.estatus1}}
                                    <i class="fa-solid fa-square-check" style="color: green; font-size: 18px"></i>
                                </ng-template>
                                <ng-template #elseBlock>Estatus: {{this.info.estatus1}}</ng-template>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <strong>Terminos y condiciones</strong>
                            <div>Moneda: {{this.info.moneda}}
                            </div>
                            <div>Dias Credito: {{this.info.dias}}
                            </div>
                            <div>Referencia: {{this.info.referencia}}
                            </div>
                            <div>Codiciones: {{this.info.condicionespago}}
                            </div>
                            <div>Observaciones: {{this.info.observaciones}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 20px;">
                <ejs-grid #tabla [dataSource]='dataDetalle' gridLines='Both' allowSorting='true' allowResizing='true'>
                    <e-columns>
                        <ng-template #template ngFor let-column [ngForOf]="columns">
                            <e-column *ngIf="column.nombre.toUpperCase().startsWith('ID')" [field]="column.nombre" width="0">
                            </e-column>
                            <e-column *ngIf="!column.nombre.toUpperCase().startsWith('ID') && column.nombre !== 'Costo' && column.nombre !== 'Descripcion'" [field]="column.nombre" minWidth="100" width='130' format="n2" [textAlign]="column.tipo === 'numeric' ? 'right': 'left'">
                            </e-column>
                            <e-column *ngIf="column.nombre.toUpperCase().startsWith('DESCRIPCION')" [field]="column.nombre" minWidth="200">
                            </e-column>
                            <e-column *ngIf="column.nombre === 'Costo'" [field]="column.nombre" minWidth="100" width="100" format="n2" [textAlign]="column.tipo === 'numeric' ? 'right': 'left'">
                                <ng-template #template let-item>
                                    {{item.Costo | number: '.2-2'}}
                                    <button *ngIf="this.info.estatus == 22" class="btn btn-outline-success" (click)="onRowSelected(item)"><i class="fa-solid fa-pen-to-square"></i></button>
                                </ng-template>
                            </e-column>
                        </ng-template>
                    </e-columns>
                </ejs-grid>
            </div>

            <!-- flex-column-reverse flex-md-row -->
            <!-- utilizado para intercambiar posicion de columnas al cambio estilo responsivo -->
            <div class="row flex-column-reverse flex-md-row">
                <div class="col-md-6">
                    <div class="card-body" style="padding: 5px 0px;">
                        <div class="p-4 p-md-2 mb-4 text-white rounded bg-dark">
                            <h5>Expediente de la cotización</h5>
                            <div class="mb-1">
                                Documentos de todo tipo que forman el soporte de la cotización.
                            </div>
                            <div style="position: absolute; top: 15px; right: 30px; font-size: 30px">
                                <i class="fa-solid fa-folder-open"></i>
                            </div>
                        </div>
                        <ejs-grid [dataSource]='data' gridLines='Both' [allowSorting]='true' [allowResizing]='true' allowReordering='true' allowPaging='true' [pageSettings]='initialPage' [rowHeight]='20'>
                            <e-columns>
                                <e-column field='id' headerText='' width='70'>
                                    <ng-template #template let-item>
                                        <div style="display: inline-flex;">
                                            <button disabled class="btn btn-outline-primary" (click)="descarga(item.id, item.nombre)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                            <button disabled *ngIf="this.info.estatus == 22" class="btn btn-borrar-sm" (click)="confirmarEliminarArchivo(item.id)" title="Borrar" [disabled]="true"></button>
                                        </div>
                                    </ng-template>
                                </e-column>
                                <e-column field='nombre' headerText='Nombre'></e-column>
                                <e-column field='fecha_registro' headerText='Fecha' width='200' minWidth='200' type="date" format="dd-MM-yyyy hh:mm:ss">
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
                <div class="col-md-6 justify-content-end">
                    <div class="card-body" style="padding: 5px 0px;">
                        <div class="p-md-2 text-white rounded bg-dark">
                            <div class="row">
                                <div class="col-6">
                                    <h5>Total de la cotizacion</h5>
                                    No incluye impuestos
                                </div>
                                <div class="col-6 d-flex justify-content-end">
                                    <!-- <span style="font-size: 24px;">{{this.totales.importe | number: '.2-2'}}</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<ejs-dialog id="dlgdetalle" #dlgdetalle header='DETALLE' width='400px' isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fa-solid fa-key"></i> Información General.
        </div>
    </ng-template>
    <ng-template #content>
        <form [formGroup]="formDetalle">
            <div class="row" style="display: flex; margin-top: 15px;">
                <div class="col-md-12">
                    <label><b>Precio:</b></label>
                    <ejs-numerictextbox formControlName='precio' placeholder="Ingrese Precio Nuevo" textAlign='right' [validateDecimalOnType]='false' [showSpinButton]='false' decimals='2' style="background-color: cornsilk;">
                    </ejs-numerictextbox>
                </div>
            </div>
            <div class="row" style="display: flex; margin-top: 15px;">
                <div class="col-md-12">
                    <label><b>Fecha Entrega:</b></label>
                    <ejs-datepicker formControlName='fecha_entrega' format="dd-MM-yyyy"></ejs-datepicker>
                </div>
            </div>
            <div class="row" style="display: flex; margin-top: 15px;">
                <div class="col-md-12">
                    <label><b>Observaciones</b></label>
                    <textarea type="text" formControlName="observaciones" class="form-control" placeholder="Observaciones" style="height: 100px"></textarea>
                </div>
            </div>
        </form>
        <div class="callout callout-botones">
            <button type="button" class="btn btn-guardar rounded-0 mb-2 mr-2" style="margin-top: 5px;" (click)="guardarPrecio()" title="Guardar Información">
                Guardar
            </button>
        </div>
    </ng-template>
</ejs-dialog>