import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})


export class CompraService {

    constructor(
        private http: HttpClient
    ) { }

    /**
     * Metodo para obtener la información del documento de compra
     */
    public getCompra(_id: Number): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/compra/compra/${_id}`);
    }

    /**
     * Metodo para obtener la información del documento de compra de manera publica
     * Esto aplica cuando el proveedor no esta logeado
     * El ID debemos obtenerlo codificado en Base64
     */
    public getCompraPublica(_id: string): Observable<any> {
        const parametros: HttpParams = new HttpParams().append('id', _id);
        return this.http.get(`${environment.base_url}/publico/compras/ordencompra`, { params: parametros });
    }

    public setEstatus(_id_doc_compra: Number, _id_docestatus: Number,): Observable<any> {
        return this.http.post(`${environment.base_url}/proveedor/compra/setEstatus/${_id_doc_compra}/${_id_docestatus}`, {});
    }

    public getDetalle(_id: Number): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/compra/detalle/${_id}`);
    }

    public getFacturaPDF(_id: Number): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/compra/detalle/${_id}`);
    }

    public getFacturaXML(_id: Number): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/compra/detalle/${_id}`);
    }

}

