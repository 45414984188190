import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { comprasService } from './compras.service';

@Injectable({
    providedIn: 'root'
})
export class ComprasResolver implements Resolve<boolean> {

    constructor(
        private servicio: comprasService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
        return this.servicio.cargaDocumentos();
    }

}
