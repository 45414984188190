import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErroresService } from '../../../shared/errores.service';
import { MdlDetalle } from '../Models/MdlDetalle';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  public getCotizacion(_id: Number): Observable<any> {
    return this.http
      .get(`${environment.base_url}/proveedor/cotizaciones/cotizacion/${_id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public setEstatus(_id_doc_compra: Number, _id_proveedor: Number, _id_doc_estatus: Number): Observable<any> {
    return this.http.get(`${environment.base_url}/proveedor/cotizaciones/setEstatus/${_id_doc_compra}/${_id_proveedor}/${_id_doc_estatus}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public Tbcotizaciones(): Observable<any> {
    return this.http.get(`${environment.base_url}/proveedor/cotizaciones/Tbcotizaciones`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public getDetalle(_id: Number): Observable<any> {
    return this.http.get(`${environment.base_url}/proveedor/cotizaciones/detalle/${localStorage.getItem('id')}/${_id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para guardar el precio, fecha y observaciones de lo cotizado
   * @param modelo 
   * @returns 
   */
  public Guardar(modelo: MdlDetalle) {
    return this.http
      .post(`${environment.base_url}/proveedor/cotizaciones/grdPrecio`, modelo)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Metodo para eliminar el registro de la partida cotizada
   * Esto será utilizado para conocer los productos o servicios que SI ofrece el proveedor
   * @param modelo 
   * @returns 
   */
  public Eliminar(id_doc_compra_detalle: number, id_proveedor: number) {
    return this.http
      .post(`${environment.base_url}/proveedor/cotizaciones/eliminarRegistro/${id_doc_compra_detalle}/${id_proveedor}`, {})
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }


  /**
   * Metodo para obtener listado de archivos disponibles para el documento cargado
   * @returns 
   */
  public lstarchivos(id: number): Observable<any> {
    return this.http.get(`${environment.base_url}/proveedor/cotizaciones/lstarchivos/${id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Descargamos el archivo seleccionado
   * @param id Numero identificador de la actividad
   * @returns 
   */
  public getDescarga(id: number): Observable<any> {
    let resonseType: any = 'blob';
    let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get<any>(`${environment.base_url}/proveedor/cotizaciones/descarga/${id}`, { responseType: resonseType, headers: headers })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          error.error.text().then(text => {
            DialogUtility.alert({
              title: 'Informacion Arcarius',
              content: JSON.parse(text).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
          });
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  /**
   * Elimnadmos el archivo con base en el identificador
   * @param id Numero de identificacion del archivo
   * @returns Observable para su ejecución
   */
  public getEliminar(id: number): Observable<any> {
    return this.http.post<any>(`${environment.base_url}/proveedor/cotizaciones/eliminar/${id}`, {})
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.error instanceof Blob) {
            err.error.text().then(text => {
              DialogUtility.alert({
                title: 'Informacion Arcarius',
                content: JSON.parse(text).mensaje,
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
              });
            });
          } else {
            DialogUtility.alert({
              title: 'Informacion Arcarius',
              content: JSON.parse(JSON.stringify(err.error)).mensaje,
              showCloseIcon: true,
              closeOnEscape: true,
              animationSettings: { effect: 'Zoom' }
            });
          }
          return throwError(this.errores.getErrores(err));
        })
      );
  }

}
