import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Grid, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DocumentoService } from './Services/documento.service';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.scss']
})
export class DocumentoComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  private cadena: string = '';
  @ViewChild('dlgdetalle') public dlgdetalle: DialogComponent;
  @ViewChild('tabla') tabla: Grid;
  public data: any;
  public dataDetalle: any;
  public initialPage: PageSettingsModel;
  public columns: any;
  public info: any;
  public idurl: number;
  public fechaactual: Date = new Date();
  public ano_actual: any;
  public simbolo: String = "";

  public totales: any;
  ClickedRow: any;

  constructor(
    private route: ActivatedRoute,
    private servicio: DocumentoService,
    public domSanitizer: DomSanitizer
  ) {
    this.route.data.subscribe((resp: any) => {
      this.data = JSON.parse(JSON.stringify(resp.listado)).respuesta;
      this.info = this.data[0];
      this.idurl = Number(this.info.id_doc_compra_encabezado);
      this.simbolo = this.info.simbolo2;
    });

    this.route.data.subscribe((resp: { detalle: any[] }) => {
      let datosResolver = JSON.parse(JSON.stringify(resp.detalle)).respuesta;
      this.dataDetalle = datosResolver.tbDocCompra;
      this.columns = datosResolver.columnas;
    });

    //=============================================================================================
    // Obtenemos la cadena de identificacion proporcionada en la URL en Base64
    //=============================================================================================
    this.route.queryParams.subscribe(
      resp => {
        this.cadena = resp.id;
      }
    );
    if (this.data == undefined || this.data == "") {
      DialogUtility.alert({
        title: 'ArcariusERP [Info]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
        content: '<b>' + "No existe esta orden de compra" + '</b>',
        showCloseIcon: true,
        closeOnEscape: true,
        animationSettings: { effect: 'Zoom' },
      });
      return;
    }
  }

  ngOnInit(): void {
    this.initialPage = { pageSizes: true, pageSize: 20 };
    this.servicio.getDetalle(this.info.id_doc_compra_encabezado).subscribe((resp) => {
      this.dataDetalle = resp.respuesta.tbDocCompra;
      this.columns = resp.respuesta.columnas;
    });
  }


}