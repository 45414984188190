import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { DialogUtility } from '@syncfusion/ej2-popups';
import { UsuarioService } from '../services/usuario.service';
import { MdlUsuario } from '../models/MdlUsuario';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent {
  @ViewChild('dlgBD') public dlgBD: DialogComponent;
  public listaDB: any;
  public usuario: MdlUsuario;

  public loginForm = this.fb.group({
    database: ['ADMIN'],
    user: ['', [Validators.required]],
    password: ['', Validators.required]
  });

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private fb: UntypedFormBuilder,
    private usuarioService: UsuarioService
  ) { }

  /**
   * Metodo para tomar la base de datos seleccionada y hacer la conexion
   * @param args pasamos el listado de las bases de datos para mayor facilidad al usuario
   */
  public onRowSelected(args: any) {
    this.dlgBD.hide();
    this.loginForm.controls['database'].setValue(args.data.base);
    this.usuarioService.login(this.loginForm.value).subscribe(
      (resp) => {
        if (JSON.parse(JSON.stringify(resp)).numero < 0) {
          DialogUtility.alert({
            title: 'Informacion Arcarius',
            content: JSON.parse(JSON.stringify(resp)).mensaje,
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        } else {
          localStorage.setItem('token', JSON.parse(JSON.stringify(resp)).Token);
          localStorage.setItem('Correo', JSON.parse(JSON.stringify(resp)).Correo);
          localStorage.setItem('id', JSON.parse(JSON.stringify(resp)).Id);
          localStorage.setItem('empresa', JSON.parse(JSON.stringify(resp)).Empresa);
          this.router.navigateByUrl(`/pages/index`);
        }
      },
      (err) => {
        DialogUtility.alert({
          title: 'Informacion Arcarius',
          content: err.error.message,
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      }
    );
  }

  /**
   * Metodo para listar las bases de datos a que tiene acceso el usuario
   * @returns regresa en formato json el listado de las bases de datos y mostramos en pantalla
   */
  public lstBD() {
    this.usuarioService.lstBD(this.loginForm.value).subscribe(
      (respuesta) => {
        if (JSON.parse(JSON.stringify(respuesta)).numero < 0) {
          const modalRef = this.modalService.open(dlgMensajeError);
          modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
          modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
        } else {
          this.listaDB = JSON.parse(JSON.stringify(respuesta)).respuesta.listbase;
          this.dlgBD.show();
        }
      },
      (err) => {
        const modalRef = this.modalService.open(dlgMensajeError);
        modalRef.componentInstance.encabezado = 'Arcarius ERP';
        modalRef.componentInstance.contenido = err.error.message;
      }
    );
  }

}
