import { AfterContentChecked, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Grid, PageSettingsModel, TextWrapSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogUtility, Dialog } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CotizacionService } from './services/cotizacion.service';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.scss']
})
export class CotizacionComponent implements OnInit, AfterContentChecked {
  public archivoArea: HTMLElement;
  public tbArchivo: any;
  private UtilComponent: any;
  private IdArchivo: number = -1;
  public wrapSettings: TextWrapSettingsModel = { wrapMode: 'Content' };

  public data: any;
  public encabezado: any;
  public estatus: any;
  public fechaactual: Date = new Date();
  public ano_actual: any;
  public simbolo: String = "";
  //************************************************************************************************************************************ */
  //           GRID: Configuraciones                                                            */
  //************************************************************************************************************************************ */
  @ViewChild('dlgdetalle') public dlgdetalle: DialogComponent;
  @ViewChild('tabla') tabla: Grid;
  public initialPage: PageSettingsModel;
  public columns: any;
  public totales: any;
  ClickedRow: any;


  //********************************************************************************************************************************************************************************************************** */
  // Variables Utilizadas
  //********************************************************************************************************************************************************************************************************** */
  // 
  public IdUrl: number = -1;

  public formDetalle: UntypedFormGroup = new UntypedFormGroup({});
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private servicio: CotizacionService,
    public domSanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    //=============================================================================================
    // Obtenemos la cadena de identificacion proporcionada en la URL en Base64
    //=============================================================================================
    this.route.params.subscribe((params: Params) => { this.IdUrl = params.id; });
    // hacemos la carga del documento
    //****************************************************************** */
    // Listados: instanciamos los listados
    this.route.data.subscribe((resp: { listado: any[] }) => {
      this.tbArchivo = JSON.parse(JSON.stringify(resp.listado[0])).respuesta;
      let datosResolver = JSON.parse(JSON.stringify(resp.listado[1])).respuesta;
      this.encabezado = datosResolver.encabezado;
      this.estatus = datosResolver.estatus;
      this.columns = datosResolver.detalle.columnas;
      this.data = datosResolver.detalle.detalle;
      this.totales = datosResolver.detalle.totales;


    });

    this.crearFormulario(-1, -1);
  }

  ngOnInit(): void { }

  ngAfterContentChecked(): void {
    this.archivoArea = document.getElementById('droparea');
  }


  private crearFormulario(id_doc_compra_detalle: number, id_doc_compra_detalle_cotiza: number) {
    this.formDetalle = this.fb.group({
      id: [id_doc_compra_detalle_cotiza, [Validators.required]],
      id_doc_compra_detalle: [id_doc_compra_detalle, [Validators.required]],
      id_proveedor: [localStorage.getItem('id')],
      precio: [0.00, [Validators.required]],
      fecha_entrega: [new Date, [Validators.required]],
      observaciones: ['', [Validators.required]]
    });
  }

  /**
   * Metodo para lanzar el buscardor de archivos relacionado con el uploader.
   * @returns 
   */
  public browseClick() {
    document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
  }

  /**
   * Metodo que se ejecuta cada vez que seleccionamos un archivo, ya sea por arrastre o busqueda.
   * El archivo seleccionado será almacenado dentro de una variable global, la cual posteriormente
   * es utilizada para guardar el archivo con el metodo "guardarImagen()"
   * 
   * @param args Son las propiedades del componente uploader, para obtener el archivo seleccionado
   * que esta en la posicion 0. El uploader esta configurado para solo obtener 1 archivo a la vez
   */
  public selected(archivo: UploaderComponent) {
    this.blockUI.start('Procesando...');
    let formulario = new FormData();
    formulario.append('archivo', archivo.filesData[0].rawFile, archivo.filesData[0].name);
    formulario.append('Content-Type', 'application/json');
    formulario.append('Accept', `application/json`);
    this.http.post(`${environment.base_url}/proveedor/cotizaciones/carga/${this.encabezado.id_doc_compra_encabezado}`, formulario).subscribe(resp => {
      let respuesta = JSON.parse(JSON.stringify(resp));
      if (respuesta.numero > 0) {
        //==============================================================================
        // Cargamos los nuevos valores de los archivoExpediente procesados
        //==============================================================================
        this.servicio.lstarchivos(this.encabezado.id_doc_compra_encabezado).subscribe(resp => {
          this.tbArchivo = resp.respuesta;
        });
        //==============================================================================
        // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
        // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
        //==============================================================================
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        //==============================================================================
        this.blockUI.stop();
      } else {
        this.blockUI.stop();
        DialogUtility.alert({
          title: '<span class="dialogo-titulo-error">' + respuesta.titulo + '<i style="color: #fff" class="fas fa-ban"></i></span>',
          content: '<b>' + respuesta.mensaje + '</b>',
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      }
    });
  }

  /**
  * Metodo para la descarga del archivo desde la API
  * 
  * Se utiliza un elemento <a> para gestionar la url de descarga, así como el nombre del archivo
  * 
  * @id Numero o identificador de la imagen
  * @nombre Nombre del archivo con el cual se descargara dicho archivo
  * @returns Ejecuta el metodo "click()" del elemento '<a>'
  */
  public descarga(id: number, nombre: string) {
    this.blockUI.start('Procesando...');
    this.servicio.getDescarga(id).subscribe(resp => {
      const element = document.createElement('a');
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      element.href = window.URL.createObjectURL(blob);
      element.download = nombre;
      element.click();
      this.blockUI.stop();
    });
  }

  /**
   * Metodo para confirmar la eliminacion de archivo importado a la cotizacion
   * @param id 
   */
  public confirmarEliminarArchivo(id) {
    this.IdArchivo = id;
    this.UtilComponent = DialogUtility.confirm({
      title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
      content: '<h6>' + "Estas seguro de eliminar el archivo ?" + '</h6>',
      okButton: { text: 'Si', click: this.EliminarArchivo.bind(this) },
      cancelButton: { text: 'No' },
      showCloseIcon: true,
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' }
    });
  };

  /**
   * Metodo para eliminar el archivo selecionado
   * @param componente El dialogo de confirmacion para eliminar el archivo
   */
  public EliminarArchivo(componente: Dialog) {
    this.blockUI.start('Procesando...');
    //==============================================================================================
    //--> creamos la funcionalidad de eliminar
    //==============================================================================================
    this.servicio.getEliminar(this.IdArchivo).subscribe((resp) => {
      if (resp.numero > 0) {
        this.servicio.lstarchivos(this.encabezado.id_doc_compra_encabezado).subscribe(
          resp => {
            this.tbArchivo = resp.respuesta;
            this.blockUI.stop();
          });
      } else {
        this.blockUI.stop();
        DialogUtility.alert({
          title: '<span class="dialogo-titulo-error">' + resp.titulo + '<i style="color: #fff" class="fas fa-ban"></i></span>',
          content: '<b>' + resp.mensaje + '</b>',
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      }
      this.UtilComponent.hide();
      this.IdArchivo = -1;
    });
  }

  public btnConfirmado() {
    this.blockUI.start('trabajando...');
    this.servicio.setEstatus(this.IdUrl, Number(localStorage.getItem('id')), 50).subscribe((resp) => {
      if (resp.numero < 0) {
        DialogUtility.alert({
          title: '<span class="dialogo-titulo-error">' + 'ArcariusERP [Info]' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
          content: '<b>' + resp.mensaje + '</b>',
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      } else {
        this.servicio.getCotizacion(this.IdUrl).subscribe((resp) => {
          this.encabezado = resp.respuesta.tbDocCompra[0];
          this.estatus = resp.respuesta.estatus;
        });
        DialogUtility.alert({
          title: resp.titulo + '<span style="color: #28a745">' + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
          content: '<b>' + resp.mensaje + '</b>',
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      }
    });
    this.blockUI.stop();
  }

  public onRowSelected(args: any) {
    this.crearFormulario(args.Id, args.id_doc_compra_detalle_cotiza);
    this.dlgdetalle.show();
  }

  /**
   * Metodo para eliminar el precio, fecha y observaciones de una partida cotizada
   * @param args 
   */
  public onEliminar(args: any) {
    this.blockUI.start('Procesando...');
    this.servicio.Eliminar(args.Id, Number(localStorage.getItem('id'))).subscribe(resp => {
      let respuesta = JSON.parse(JSON.stringify(resp));
      if (respuesta.numero > 0) {
        this.servicio.getDetalle(this.IdUrl).subscribe((resp) => {
          this.data = resp.respuesta.tbDocCompra;
          this.blockUI.stop();
        });
      } else {
        this.blockUI.stop();
        DialogUtility.alert({
          title: respuesta.titulo + '<span class="dialogo-titulo-error">' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
          content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
          showCloseIcon: true,
          closeOnEscape: true,
          animationSettings: { effect: 'Zoom' }
        });
      }
    });
  }

  public guardarPrecio() {
    this.servicio.Guardar(this.formDetalle.value).subscribe(
      resp => {
        let respuesta = JSON.parse(JSON.stringify(resp));
        if (respuesta.numero < 0) {
          this.dlgdetalle.hide();
          DialogUtility.alert({
            title: respuesta.titulo + '<span class="dialogo-titulo-error">' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
            content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
          this.servicio.getDetalle(this.IdUrl).subscribe((resp) => {
            this.data = resp.respuesta.tbDocCompra;
          });
        } else {
          /**
           * Recargamos el recursor para vizualizar la informacion del grid actualizada => precio Descuento y Oobservaciones  
           */
          this.servicio.getDetalle(this.IdUrl).subscribe((resp) => {
            console.log(resp);
            this.data = resp.respuesta.detalle;
            this.totales = resp.respuesta.totales;
          });
          this.dlgdetalle.hide();
          this.blockUI.stop();
          DialogUtility.alert({
            title: respuesta.titulo + '<span style="color: #28a745">' + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
            content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
            showCloseIcon: true,
            closeOnEscape: true,
            animationSettings: { effect: 'Zoom' }
          });
        }
      }
    );
  }

}