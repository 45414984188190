<div class="modal-header">
    <h4 class="modal-title encabezado">{{encabezado}}</h4>
    <!-- <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button> -->
</div>
<div class="modal-body">
    <div class="contenido" [innerHtml]="contenido | safeHtml"></div>
</div>
<div class="modal-footer piepagina">
    <button type="button" class="btn boton" autofocus aria-label="Close" (click)="activeModal.close('Close click')">
        Entendido
    </button>
</div>