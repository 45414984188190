import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CompraService } from './compra.service';

@Injectable({
    providedIn: 'root'
})
export class CompraResolver implements Resolve<boolean> {
    errores: any;
    constructor(
        private service: CompraService

    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
        //==========================================================
        // Confirmamos la información que obtenemos desde la URL
        // Podemos traer un id codificado en Base64
        //==========================================================
        if (route.queryParams.id !== undefined) {
            return this.service.getCompraPublica(route.queryParams.id);
        }

        //==========================================================
        // Podemos tener el id de un documento de compra
        //==========================================================
        if (route.params['id'] !== undefined) {
            return this.service.getCompra(route.params['id']);
        }



    }


}
