import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';
import { MdlProveedorPassword } from '../../perfil/Models/MdlProveedorPassword.model';

@Injectable({
    providedIn: 'root'
})
export class comprasService {

    constructor(
        private http: HttpClient,
        private errores: ErroresService
    ) { }


    /**
     * Metodo para obtener los documentos del proveedor.
     * Los documentos seran las Ordenes de Compra y en su caso las Notas de Credito
     * @returns 
     */
    public cargaDocumentos(): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/compra/documentos_compras/${localStorage.getItem('id')}`);
    }

    public cargaProducto(id: number): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/producto/Conf/${id}`);
    }

    /**
     * Metodo para obtener la informacion del proveedor
     * 
     * @returns 
     */
    public datos_proveedor(): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/perfil/cargaperfil/${(JSON.parse(localStorage.getItem('id')))}`);
    }

    public DocumentosSolicitados(): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/perfil/TbDocumentosSolicitadosProveedor/${(JSON.parse(localStorage.getItem('id')))}`);
    }
    public TbExpedientesProveedor(): Observable<any> {
        return this.http.get(`${environment.base_url}/proveedor/perfil/TbExpedientesProveedor/${(JSON.parse(localStorage.getItem('id')))}`);
    }

    public borrarDocumento(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/proveedor/perfil/borrarExpediente/${id}`, {});
    }
    public borrarListado(id: number): Observable<any> {
        return this.http.post<any>(`${environment.base_url}/proveedor/perfil/borrarDoc/${id}`, {});
    }

    /**
   * Metodo para asignar una contraseña al proveedor
   * @param modelo 
   * @returns 
   */
  public setPassword(modelo: MdlProveedorPassword): Observable<any> {
    return this.http.post(`${environment.base_url}/compra/proveedor/setPassword`, modelo);
  }
    
}