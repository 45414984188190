import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { MdlDetalle } from '../../cotizacion/Models/MdlDetalle';
@Injectable({
  providedIn: 'root'
})
export class DocumentoService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }

  public getCompra(_id: Number): Observable<any> {
    return this.http
      .get(`${environment.base_url}/proveedor/compra/compra/${_id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public setEstatus(_id_doc_compra: Number, _id_docestatus: Number,): Observable<any> {
    return this.http.get(`${environment.base_url}/proveedor/compra/setEstatus/${_id_doc_compra}/${_id_docestatus}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public getDetalle(_id: Number): Observable<any> {
    return this.http.get(`${environment.base_url}/proveedor/compra/detalle/${_id}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }
}
