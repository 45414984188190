<div class="tab-pane fade show active" id="tab_proceso" role="tabpanel" aria-labelledby="tab_proceso-tab">
    <!-- Generacion de Componentes de Tipo Chica == 1-->
    <div class="card-group">
        <div *ngFor="let consulta of data">
            <div *ngIf="consulta.tipo == 1 && consulta.medida == 1">
                <!--Grafico de Barras-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 2 && consulta.medida == 1">
                <!--Grafico de Pie-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                            <e-accumulation-series-collection>
                                <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true' explodeOffset='10%' [palettes]='consulta.colors'>
                                </e-accumulation-series>
                            </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 3 && consulta.medida == 1">
                <!--Grafico de Dona-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                            <e-accumulation-series-collection>
                                <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true' explodeOffset='10%'
                                    [palettes]='consulta.colors'>
                                </e-accumulation-series>
                            </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 4 && consulta.medida == 1">
                <!--Grafico de Lineas-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [width]='2' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 5 && consulta.medida == 1">
                <!--informacion en Tabla-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="min-height: 300px;">
                        <ejs-grid [dataSource]='consulta.dato' height='100%'>
                        </ejs-grid>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 6 && consulta.medida == 1">
                <!--Grafico de Barra y Linea-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato; let i = index" [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 7 && consulta.medida == 1">
                <!--Grafico de Embudo-->
            </div>
            <div *ngIf="consulta.tipo == 8 && consulta.medida == 1">
                <!--No aplica, es para exportacion de datos-->
            </div>
            <div *ngIf="consulta.tipo == 9 && consulta.medida == 1">
                <!--Tarjeta-->
                <div class="card" style="width: 12rem; margin-right: 5px; margin-bottom: 5px; ">
                    <div class="card-header" style="background-color: #424141; color: white;flex: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" [innerHTML]="consulta.descripcion | safeHtml">
                    </div>
                    <div class="card-body" style="min-height: 70px; padding-left: 5px; padding-top: 5px;">
                        <div [innerHTML]="consulta.valor | safeHtml"></div>
                    </div>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 10 && consulta.medida == 1">
                <!--Odometro-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <ejs-circulargauge style='display:block;' centerY='70%'>
                        <e-axes>
                            <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270 endAngle=90>
                                <e-pointers>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30' radius='80%' type='RangeBar'>
                                    </e-pointer>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9' radius='90%'></e-pointer>
                                </e-pointers>
                                <e-ranges>
                                    <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}' end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                                </e-ranges>
                            </e-axis>

                        </e-axes>
                    </ejs-circulargauge>

                </div>
            </div>
        </div>
    </div>

    <!-- Generacion de Componentes de Tipo Mediana == 2-->
    <div class="card-columns">
        <div *ngFor="let consulta of data">
            <div *ngIf="consulta.tipo == 1 && consulta.medida == 2">
                <!--Grafico de Barras-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 2 && consulta.medida == 2">
                <!--Grafico de Pie-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                            <e-accumulation-series-collection>
                                <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true' explodeOffset='10%' [palettes]='consulta.colors'>
                                </e-accumulation-series>
                            </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 3 && consulta.medida == 2">
                <!--Grafico de Dona-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                            <e-accumulation-series-collection>
                                <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true' explodeOffset='10%'
                                    [palettes]='consulta.colors'>
                                </e-accumulation-series>
                            </e-accumulation-series-collection>
                        </ejs-accumulationchart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 4 && consulta.medida == 2">
                <!--Grafico de Lineas-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [width]='2' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 5 && consulta.medida == 2">
                <!--informacion en Tabla-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="min-height: 300px;">
                        <ejs-grid [dataSource]='consulta.dato' height='100%'>
                        </ejs-grid>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 6 && consulta.medida == 2">
                <!--Grafico de Barra y Linea-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary" style="height: 300px;">
                        <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                            <e-series-collection>
                                <e-series *ngFor="let registro of consulta.dato; let i = index" [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                                </e-series>
                            </e-series-collection>
                        </ejs-chart>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 7 && consulta.medida == 2">
                <!--Grafico de Embudo-->
            </div>
            <div *ngIf="consulta.tipo == 8 && consulta.medida == 2">
                <!--No aplica, es para exportacion de datos-->
            </div>
            <div *ngIf="consulta.tipo == 9 && consulta.medida == 2">
                <!--Tarjeta-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <div class="card-body text-primary">
                        <div [innerHTML]="consulta.valor | safeHtml"></div>
                        <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="consulta.tipo == 10 && consulta.medida == 2">
                <!--Odometro-->
                <div class="card ">
                    <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                    <ejs-circulargauge style='display:block;' centerY='70%'>
                        <e-axes>
                            <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270 endAngle=90>
                                <e-pointers>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30' radius='80%' type='RangeBar'>
                                    </e-pointer>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9' radius='90%'></e-pointer>
                                </e-pointers>
                                <e-ranges>
                                    <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}' end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                                </e-ranges>
                            </e-axis>

                        </e-axes>
                    </ejs-circulargauge>

                </div>
            </div>
        </div>
    </div>

    <!-- Generacion de Componentes de Tipo Grande == 3-->
    <div *ngFor="let consulta of data" style="margin-bottom: 10px;">
        <div *ngIf="consulta.tipo == 1 && consulta.medida == 3">
            <!--Grafico de Barras-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary" style="height: 300px;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' [palettes]='consulta.colors'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 2 && consulta.medida == 3">
            <!--Grafico de Pie-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary" style="height: 300px;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true' explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 3 && consulta.medida == 3">
            <!--Grafico de Dona-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary" style="height: 300px;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;' [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip">
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true' explodeOffset='10%'
                                [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 4 && consulta.medida == 3">
            <!--Grafico de Lineas-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary" style="height: 300px;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [width]='2' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 5 && consulta.medida == 3">
            <!--informacion en Tabla-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary" style="min-height: 300px;">
                    <ejs-grid [dataSource]='consulta.dato' height='100%'>
                    </ejs-grid>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 6 && consulta.medida == 3">
            <!--Grafico de Barra y Linea-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary" style="height: 300px;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis' [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato; let i = index" [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 7 && consulta.medida == 3">
            <!--Grafico de Embudo-->
        </div>
        <div *ngIf="consulta.tipo == 8 && consulta.medida == 3">
            <!--No aplica, es para exportacion de datos-->
        </div>
        <div *ngIf="consulta.tipo == 9 && consulta.medida == 3">
            <!--Tarjeta-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-body text-primary">
                    <div [innerHTML]="consulta.valor | safeHtml"></div>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
        </div>
        <div *ngIf="consulta.tipo == 10 && consulta.medida == 3">
            <!--Odometro-->
            <div class="card ">
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <ejs-circulargauge style='display:block;' centerY='70%'>
                    <e-axes>
                        <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270 endAngle=90>
                            <e-pointers>
                                <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30' radius='80%' type='RangeBar'>
                                </e-pointer>
                                <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9' radius='90%'>
                                </e-pointer>
                            </e-pointers>
                            <e-ranges>
                                <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}' end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                            </e-ranges>
                        </e-axis>

                    </e-axes>
                </ejs-circulargauge>

            </div>
        </div>
    </div>
</div>