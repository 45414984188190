<div style="text-align: center;">
    <h2>Soluciones y Servicios efectivamente confiables</h2>
    <p>
        Los objetivos principales de nuestros distribuidores es proporcionar a nuestros clientes las soluciones que su
        empresa necesita.
    </p>
    <a class="siteLink" href="https://arcariuserp.com" target="_blank">Conocer mas...</a>
</div>
<div style="text-align: center; margin-top: 80px;">
    <img src="assets/images/home-bg-1-img.png" alt="">
</div>