import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ComprasComponent } from './pages/compras/compras.component';
import { ListaBDComponent } from './pages/lista-bd/lista-bd.component';
import { ProductoComponent } from './pages/producto/producto.component';
import { PagesComponent } from './pages/pages.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { IndexComponent } from './pages/index/index.component';
import { TableroResolver } from './services/tablero.resolver';
import { DocumentoComponent } from './pages/documento/documento.component';
import { PerfilResolver } from './services/perfil.resolver';
import { CompraComponent } from './pages/compra/compra.component';
import { CompraResolver } from './pages/compra/services/compra.resolver';
import { CotizacionComponent } from './pages/cotizacion/cotizacion.component';
import { CotizacionResolver } from './pages/cotizacion/services/cotizacion.resolver';
import { CotizacionesComponent } from './pages/cotizaciones/cotizaciones.component';
import { CotizacionesResolver } from './pages/cotizaciones/services/cotizaciones.resolver';
import { ProductoResolver } from './pages/producto/Services/producto.resolver';
import { ComprasResolver } from './pages/compras/Services/compras.resolver';
import { Login2Component } from './auth/login2/login2.component';
import { AboutComponent } from './pages/about/about.component';

const routes: Routes = [
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      { path: 'index', component: IndexComponent, pathMatch: 'full', resolve: { listado: TableroResolver } },
      { path: 'compras', component: ComprasComponent, pathMatch: 'full', resolve: { listado: ComprasResolver } },
      { path: 'compra', component: CompraComponent, pathMatch: 'full', resolve: { listado: CompraResolver } },
      { path: 'compra/:id', component: CompraComponent, pathMatch: 'full', resolve: { listado: CompraResolver } },
      { path: 'cotizaciones', component: CotizacionesComponent, pathMatch: 'full', resolve: { listado: CotizacionesResolver } },
      { path: 'cotizacion/:id', component: CotizacionComponent, pathMatch: 'full', resolve: { listado: CotizacionResolver } },
      { path: 'documento', component: DocumentoComponent, pathMatch: 'full', resolve: { listado: ComprasResolver, } },
      { path: 'productos', component: ProductoComponent, pathMatch: 'full', resolve: { listado: ProductoResolver } },
      { path: 'perfil', component: PerfilComponent, pathMatch: 'full', resolve: { listado: PerfilResolver } },
      { path: 'about', component: AboutComponent, pathMatch: 'full' }
    ]
  },
  { path: 'lsBD', component: ListaBDComponent, pathMatch: 'full' },
  { path: 'documento', component: DocumentoComponent, pathMatch: 'full', resolve: { listado: ComprasResolver } },
  { path: 'login', component: LoginComponent },
  { path: 'login2', component: Login2Component },
  { path: '**', redirectTo: '/login2' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
