import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoginForm } from '../interfaces/login.interface';


const base_url = environment.base_url;

@Injectable({
    providedIn: 'root',
})
export class UsuarioService {
    constructor(
        private http: HttpClient,
        private router: Router
    ) { }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('empresa');
        localStorage.removeItem('Correo');
        localStorage.removeItem('id');
        this.router.navigateByUrl('/login2');
    }

    validarToken(): Observable<boolean> {
        const token = localStorage.getItem('token') || '';
        return this.http.get(`${base_url}/auth/renovar`, {
            headers: {
                'token': token,
            },
        })
            .pipe(
                tap((resp: any) => {
                    localStorage.setItem('token', resp.token);
                }),
                map((resp) => true),
                catchError((error) => of(false))
            );
    }

    login(formData: LoginForm): Observable<any> {
        return this.http.post(`${base_url}/auth/AuthProveedor`, formData);
    }

    lstBD(formData: LoginForm): Observable<any> {
        return this.http.post(`${base_url}/auth/lstBDProveedores`, formData);
    }



}
