import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { comprasService } from '../pages/compras/Services/compras.service';

@Injectable({
    providedIn: 'root'
})
export class PerfilResolver implements Resolve<boolean> {
    constructor(private service: comprasService) { }

    /**
     * Metodopara obtener los datos generales del proveedor y poder mostrarlos 
     * en su perfil
     * @param route 
     * @param state 
     * @returns 
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
        return this.service.datos_proveedor();
    }
}
