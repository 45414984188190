<section>
    <div class="table-responsive">
        <table *ngIf="this.tb_productos" class="table table-hover table-bordered">
            <thead>
                <th style="min-width: 100px;">Codigo</th>
                <th style="min-width: 200px;">Producto</th>
                <th style="min-width: 100px; text-align: right;">Precio</th>
                <th style="max-width: 70px;">Modificar</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of tb_productos">
                    <td>
                        {{item.codigo}}
                    </td>
                    <td>
                        {{item.descripcion}}
                    </td>
                    <td style="text-align: right;">
                        {{item.precio}}
                    </td>
                    <td>
                        <button type="button" class="btn btn-editar-sm " (click)="btnEditar(item)"
                            title="Modificar Precio" [disabled]="!this.configura.ajusta_precio"></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>