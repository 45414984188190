import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages.routing';
import { CommonModule } from '@angular/common';
import { ListaBDComponent } from './lista-bd/lista-bd.component';
import { MenuAccesoComponent } from './menu-acceso/menu-acceso.component';
import { ProductoComponent } from './producto/producto.component';
import { ComprasComponent } from './compras/compras.component';
import { PerfilComponent } from './perfil/perfil.component';
import { IndexComponent } from './index/index.component';
import { DocumentoComponent } from './documento/documento.component';
import { CotizacionComponent } from './cotizacion/cotizacion.component';
import { CompraComponent } from './compra/compra.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { PrecioComponent } from './producto/components/precio/precio.component';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    ListaBDComponent,
    MenuAccesoComponent,
    ProductoComponent,
    ComprasComponent,
    PerfilComponent,
    IndexComponent,
    DocumentoComponent,
    CotizacionComponent,
    CompraComponent,
    CotizacionesComponent,
    PrecioComponent,
    AboutComponent
  ],

  imports: [
    SharedModule,
    CommonModule,
    PagesRoutingModule
  ],
  providers: [

  ],
  //===============================================================================================
  // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
  // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
  //===============================================================================================
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  //===============================================================================================
})
export class PagesModule { }
