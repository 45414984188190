import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { MdlCertificado } from '../Models/MdlCertificado';
@Injectable({
  providedIn: 'root'
})
export class PerfilCfdiService {

  constructor(
    private http: HttpClient,
    private errores: ErroresService
  ) { }


  public datos_proveedor(): Observable<any> {
    return this.http
      .get(`${environment.base_url}/proveedor/perfil/cargaperfil/${(JSON.parse(localStorage.getItem('id')))}`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public GuardarCSD(modelo: MdlCertificado) {
    return this.http
      .post(`${environment.base_url}/proveedor/perfil/GuardarCSD`, modelo)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

  public GuardarSerie(modelo: MdlCertificado) {
    return this.http
      .post(`${environment.base_url}/proveedor/perfil/GuardarSerie`, modelo)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }


}
