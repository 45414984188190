<section>
  <div class="limiter">
    <div class="container-login">
      <div class="wrap-login">
        <div class="login-form-title" style="background-image: url(assets/images/entrada.png);">
        </div>
        <form class="login-form" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="lstBD()">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <i class="fas fa-user login-icono"></i>
                <label for="tfUsuario">Usuario</label>
                <input type="text" class="form-control" id="tfUsuario" aria-describedby="emailHelp"
                  placeholder="Nombre de usuario" formControlName="user">
                <small id="emailHelp" class="form-text text-muted">Nunca compartas tu acceso.</small>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <i class="fas fa-key login-icono"></i>
                <label for="tfPassword">Contraseña</label>
                <input type="password" class="form-control" id="tfPassword" placeholder="Contraseña"
                  formControlName="password">
                <small id="emailHelp" class="form-text text-muted">Cambia de manera regular tu contraseña.</small>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-4">
              <button type="submit" class="btn btn-primary btn-ingreso">
                <i class="fa-solid fa-user-check"></i>
                Ingresar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-9" style="text-align: left; margin-bottom: 30px;">
      <img src="../../assets/img/proveedor-02.png" alt="" style="width: 80%;">
    </div>
    <div class="col-md-3" style=" text-align: center; background-color: #0e2449; color: aliceblue; border-radius: 10px">
      <form [formGroup]="loginForm" (ngSubmit)="lstBD()" autocomplete="off">
        <div class="form-group">
          <label for="exampleInputEmail1">Correo Electronico</label>
          <input type="text" class="form-control" placeholder="Enter email" formControlName="user">
          <small id="emailHelp" class="form-text">Tu dirección de correo es tu usuario de
            acceso.</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Contraseña</label>
          <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
            formControlName="password">
        </div>
        <div class="form-group" style="margin-top: 10px">
          <button type="submit" class="btn btn-primary">Acceso</button>
        </div>
      </form>
    </div>
  </div> -->
</section>

<section>
  <p>Herramienta de gestión de Proveedores la cual es utiliza para generar una comunicación entre la empresa y sus
    socios comerciales que les proveen de Materiales, Productos y Servicios Profesionales.
  </p>

  <p>Uno de los objetivos de estas herramientas es la retro alimentación y comunicación, la cual es utilizada para
    mejorar los procesos internos y acelerar las operaciones comerciales entre ambas partes.</p>

  <p><strong>Arcarius ERP</strong> va mas allá, integrando esta herramienta con el resto de sus módulos, con lo cual
    aumenta el potencial de dicha herramienta.</p>

  <div class="row">
    <div class="col col-md-3">
      <p>La podemos aplicar para:</p>
      <li>Proveedores</li>
      <p>
        Te apoyamos para generar la comunicación correcta con tus Socios Comerciales.
      </p>
      <p>Solicite asesoria con su representenate <strong>Arcarius ERP</strong>, para empoderar esa relación comercial.
      </p>
      <p>
        <a href="https://arcariuserp.com" target="_blank">ArcariusERP</a>
      </p>
    </div>
    <div class="col col-md-3">
      <img src="../../assets/img/proveedor.png" alt="" style="width: 150%;">
    </div>
  </div>
</section>



<ejs-dialog id="dlgBD" #dlgBD header='Empresas disponibles' width='600px' height="300px" isModal='true'
  [visible]='false' showCloseIcon='true'>
  <ng-template #header>
    <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i> Seleccionar la empresa</div>
  </ng-template>
  <ng-template #content>
    <block-ui>
      <ejs-grid [dataSource]='listaDB' (rowSelected)="onRowSelected($event)">
        <e-columns>
          <e-column field='id' headerText='No.' width='0' textAlign='Right'></e-column>
          <e-column field='base' headerText='Empresas' width='200'></e-column>
        </e-columns>
      </ejs-grid>
    </block-ui>
  </ng-template>
</ejs-dialog>
