import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MdlUsuario } from 'src/app/auth/models/MdlUsuario';
import { listadoBDService } from 'src/app/services/listadoBD.service';

@Component({
  selector: 'app-lista-bd',
  templateUrl: './lista-bd.component.html',
  styleUrls: ['./lista-bd.component.scss']
})
export class ListaBDComponent implements OnInit {
  public data: any;
  public user: any;
  public usuario: MdlUsuario;

  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private lstadobd_Services: listadoBDService,
    private router: Router,
  ) {
    this.blockUI.start("Procesando");
    this.user = localStorage.getItem('usuario');
    this.lstadobd_Services
      .cargaListado(this.user)
      .subscribe((resp) => {
        this.data = JSON.parse(JSON.stringify(resp)).respuesta;
        this.blockUI.stop();
      });
  }

  ngOnInit(): void {
  }

  btn_enviar(args: any) {
    this.lstadobd_Services
      .cargaMenu_acceso(this.user, args)
      .subscribe((resp) => {
        this.blockUI.stop();
        // this.data = JSON.parse(JSON.stringify(resp)).respuesta;
        localStorage.setItem('user', this.user);
        localStorage.setItem('token', JSON.parse(JSON.stringify(resp)).Token);
        //=======================================================================================
        // Almacenamos la información del usuario
        //=======================================================================================
        localStorage.setItem('usuario', this.user);
        localStorage.setItem('base', args);
        //=======================================================================================
        // Navegar al Dashboard
        this.router.navigateByUrl(`/menu`);
      });
  }
}
