<div class="p-4 p-md-2 mb-12 text-white rounded bg-dark">
    <div class="col-md-12 px-0">
        <div class="row">
            <div class="col-md-3">
                <a [routerLink]="[]" title="Cambiar Logo" (click)="CambiarLogo()">
                    <img id="PerfilImagen" [src]="imagen" class="rounded" width="150" height="150">
                </a>
            </div>
            <div class="col-md-9">
                <h1 class="display-6 fst-italic">{{this.data.nombre}}</h1>
                <p class="lead my-3">{{this.data.observaciones}}</p>
            </div>
        </div>
    </div>
</div>

<div class="row mb-2">
    <div class="col-md-6">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-primary">Domicilio</strong>
                <h3 class="mb-0">{{this.data.direccion}}</h3>
                <div class="mb-1 text-muted">{{this.data.colonia}}</div>
                <strong class="d-inline-block mb-2 text-primary">Sucursales:</strong>
                <div [innerHTML]="this.data.sucursales | safeHtml"></div>

                <strong class="d-inline-block mb-2 text-primary">Contactos:</strong>
                <div [innerHTML]="this.data.contactos | safeHtml"></div>

                <strong class="d-inline-block mb-2 text-primary">Informacion Acceso:</strong>
                <div>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <p>Correo: {{correo}}</p>
                            <a href="javascript:void(0)" (click)="btnPassword()">Cambia Contraseña</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
                <strong class="d-inline-block mb-2 text-success">Fiscal</strong>
                <h3 class="mb-0">RFC: {{this.data.rfc}}</h3>
                <div class="mb-1 text-muted">{{this.data.regimen}}</div>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <ul class="list-group">
                            <li class="list-group-item" *ngIf="!this.data.clave" style="color: red;">
                                <i class="fa-solid fa-ban" style="font-size: 22px;"></i><b> Clave:</b>
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" title="Cambiar Clave"
                                    (click)="NumCertificado()">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item" *ngIf="this.data.clave" style="color: green;">
                                <i class="fa-solid fa-circle-check" style="font-size: 22px;"></i><b> Clave:</b>
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" title="Cambiar Clave"
                                    (click)="NumCertificado()">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item" *ngIf="this.data.llave" style="color: green;">
                                <i class="fa-solid fa-circle-check" style="font-size: 22px;"></i><b> CSD Archivo
                                    KEY:</b>
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" title="Importar llave .key"
                                    (click)="AgregarKEY()">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item" *ngIf="!this.data.llave" style="color: red;">
                                <i class="fa-solid fa-ban" style="font-size: 22px;"></i><b> CSD Archivo key: No
                                    Existe</b>
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" title="Importar Certificado"
                                    (click)="AgregarKEY()">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item" *ngIf="this.data.certificado" style="color: green;">
                                <i class="fa-solid fa-circle-check" style="font-size: 22px;"></i><b> CSD Archivo
                                    Cer:</b>
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" title="Importar Certificado"
                                    (click)="AgregarCER()">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item" *ngIf="!this.data.certificado" style="color: red;">
                                <i class="fa-solid fa-ban" style="font-size: 22px;"></i><b> CSD Archivo Cer: No
                                    Existe</b>
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" title="Importar Certificado"
                                    (click)="AgregarCER()">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item" *ngIf="this.data.certificado_pem" style="color: green;">
                                <i class="fa-solid fa-circle-check" style="font-size: 22px;"></i><b> CSD Certificado
                                    Pem:</b>
                                <i class="fa-solid fa-certificate"></i>
                            </li>
                            <li class="list-group-item" *ngIf="!this.data.certificado_pem" style="color: red;">
                                <i class="fa-solid fa-ban" style="font-size: 22px;"></i><b> CSD Certificado Pem: No
                                    Existe</b>
                            </li>

                            <li class="list-group-item" *ngIf="this.data.llave_pem" style="color: green;">
                                <i class="fa-solid fa-circle-check" style="font-size: 22px;"></i><b> CSD Llave Pem:</b>
                            </li>
                            <li class="list-group-item" *ngIf="!this.data.llave_pem" style="color: red;">
                                <i class="fa-solid fa-ban" style="font-size: 22px;"></i><b> CSD Llave Pem: No Existe</b>
                            </li>
                            <li class="list-group-item"><b> CSD Certificado:</b>
                                <br /> {{this.data.csd_cer_numero}}
                            </li>
                            <li class="list-group-item"><b> Fecha Vencimiento:</b>
                                <div [innerHTML]="this.data.fecha_vence | safeHtml">
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <i class="fa-solid fa-file-invoice" style="font-size: 22px; color: #007bff;"></i><b>
                                    Serie Factura:</b> {{this.data.csd_serie_factura}}
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" (click)="FRMserie(1)">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item">
                                <i class="fa-solid fa-file-invoice" style="font-size: 22px; color: #007bff;"></i><b>
                                    Folio Factura:</b> {{this.data.csd_folio_factura}}
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" (click)="FRMserie(2)">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item">
                                <i class="fa-solid fa-file-circle-minus"
                                    style="font-size: 22px; color: #ff5722;"></i><b> Serie Nota
                                    Credito:</b> {{this.data.csd_serie_notacredito}}
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" (click)="FRMserie(3)">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item">
                                <i class="fa-solid fa-file-circle-minus"
                                    style="font-size: 22px; color: #ff5722;"></i><b> Folio Nota
                                    Credito:</b> {{this.data.csd_folio_notacredito}}
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" (click)="FRMserie(4)">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item">
                                <i class="fa-solid fa-file-invoice-dollar"
                                    style="font-size: 22px; color: #e91e63;"></i><b> Serie
                                    Complemento
                                    Pago:</b> {{this.data.csd_serie_pago}}
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" (click)="FRMserie(5)">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                            <li class="list-group-item">
                                <i class="fa-solid fa-file-invoice-dollar"
                                    style="font-size: 22px; color: #e91e63;"></i><b> Folio
                                    Complemento
                                    Pago:</b> {{this.data.csd_folio_pago}}
                                <button type="button" class="btn  rounded-0 mb-2 mr-2" (click)="FRMserie(6)">
                                    <i class="fa-solid fa-file-circle-plus"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- dialogos Dlgs -->
    <ejs-dialog id="dlgArchivo" #dlgArchivo header='Carga Documento' width='800px' isModal='true' [visible]='false'
        showCloseIcon='true'>
        <ng-template #header>
            <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i>Seleccione un Archivo.</div>
        </ng-template>
        <ng-template #content>
            <block-ui>
                <div class="callout callout-primary">
                    <div id='droparea'
                        style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
                        <a href="" id="browse" (click)='browseClick()'><u>BuscarArchivo</u></a>
                        <ejs-uploader id="archivoCarga" allowedExtensions={{formato}} [autoUpload]='false'
                            (selected)="selected($event)" [dropArea]='dropEle'>
                            <ng-template #template let-data>
                                <span class="fileListwrapper">
                                    <span class="icon template-icons sf-icon-{{data.type}}"></span>
                                    <span class="name file-name">{{data.name}}
                                        ({{data.size}}bytes)</span>
                                    <span class="upload-status">{{data.status}}</span>
                                </span>
                                <span class="e-icons e-file-remove-btn" title="Remove"></span>
                            </ng-template>
                        </ejs-uploader>
                    </div>
                </div>
                <div class="callout callout-botones">
                    <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                        (click)="subirDocumento()" title="Almacenar Archivo">
                        Subir Archivo
                    </button>
                </div>
            </block-ui>
        </ng-template>
    </ejs-dialog>

    <ejs-dialog id="dlgDocProveedorSolicitado" #dlgDocProveedorSolicitado header='Carga Documento' width='800px'
        isModal='true' [visible]='false' showCloseIcon='true'>
        <ng-template #header>
            <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i>Seleccione un Archivo.</div>
        </ng-template>
        <ng-template #content>
            <block-ui>
                <div class="callout callout-primary">
                    <div id='droparea'
                        style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
                        <a href="" id="browse" (click)='browseClick()'><u>BuscarArchivo</u></a>
                        <ejs-uploader id="archivoCarga" [autoUpload]='false' (selected)="selected($event)"
                            [dropArea]='dropEle'>
                            <ng-template #template let-data>
                                <span class="fileListwrapper">
                                    <span class="icon template-icons sf-icon-{{data.type}}"></span>
                                    <span class="name file-name">{{data.name}}
                                        ({{data.size}}bytes)</span>
                                    <span class="upload-status">{{data.status}}</span>
                                </span>
                                <span class="e-icons e-file-remove-btn" title="Remove"></span>
                            </ng-template>
                        </ejs-uploader>
                    </div>
                </div>
                <div class="callout callout-botones">
                    <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                        (click)="DocProveedorSolicitados()" title="Almacenar Archivo">
                        Subir Archivo
                    </button>
                </div>
            </block-ui>
        </ng-template>
    </ejs-dialog>

    <ejs-dialog id="dlgExpediente" #dlgExpediente header='Carga Documento' width='800px' isModal='true'
        [visible]='false' showCloseIcon='true'>
        <ng-template #header>
            <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i>Seleccione un Archivo.</div>
        </ng-template>
        <ng-template #content>
            <block-ui>
                <div class="callout callout-primary">
                    <div id='droparea'
                        style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
                        <a href="" id="browse" (click)='browseClick()'><u>BuscarArchivo</u></a>
                        <ejs-uploader id="archivoCarga" [autoUpload]='false' (selected)="selected($event)"
                            [dropArea]='dropEle'>
                            <ng-template #template let-data>
                                <span class="fileListwrapper">
                                    <span class="icon template-icons sf-icon-{{data.type}}"></span>
                                    <span class="name file-name">{{data.name}}
                                        ({{data.size}}bytes)</span>
                                    <span class="upload-status">{{data.status}}</span>
                                </span>
                                <span class="e-icons e-file-remove-btn" title="Remove"></span>
                            </ng-template>
                        </ejs-uploader>
                    </div>
                </div>
                <div class="callout callout-botones">
                    <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                        (click)="subirExpedientes()" title="Almacenar Archivo">
                        Subir Archivo
                    </button>
                </div>
            </block-ui>
        </ng-template>
    </ejs-dialog>

    <ejs-dialog id="dlgclave" #dlgclave header='PASSWORD' width='400px' isModal='true' [visible]='false'
        showCloseIcon='true'>
        <ng-template #header>
            <div id="dlg-template" class="e-icon-settings"><i class="fa-solid fa-key"></i> Información General.</div>
        </ng-template>
        <ng-template #content>
            <block-ui>
                <form [formGroup]="formCertificado">
                    <div class="row" style="display: flex;">
                        <div class="col-md-12">
                            <label for="csd_clave"><b>CLAVE:</b></label>
                            <input type="text" formControlName="csd_clave" placeholder="Ingrese Clave"
                                class="form-control rounded-0" id="csd_clave">
                        </div>
                    </div>
                </form>
            </block-ui>
            <div class="callout callout-botones">
                <button type="button" class="btn btn-guardar rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                    (click)="AlmacenarPass()" title="Almacenar Clave">
                    Almacenar
                </button>
            </div>
        </ng-template>
    </ejs-dialog>
</div>

<div class="card-body">
    <block-ui>
        <div class="row mb-2">
            <div class="col-md-6">
                <div class="p-4 p-md-2 mb-4 text-white rounded bg-dark">
                    <h5>Expediente electrónico</h5>
                    <div class="mb-1 text-muted">
                        Documentos de todo tipo que forman parte del expediente del proveedor
                    </div>
                    <div style="position: absolute; top: 0px; right: 14px; font-size: 30px">
                        <i class="fa-solid fa-folder-open"></i>
                    </div>
                </div>
                <div class="callout callout-botones">
                    <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2" title="Capturar Expediente"
                        (click)="NuevoExpediente()" [disabled]="!data.web_ajusta_expediente">
                        Nuevo
                    </button>
                </div>
                <ejs-grid [dataSource]='dataProveedor' gridLines='Both' [toolbar]='toolbarOptions' [allowSorting]='true'
                    [allowResizing]='true' allowReordering='true' allowPaging='true' [pageSettings]='initialPage'
                    [rowHeight]='20'>
                    <e-columns>
                        <e-column field='id' headerText='' width='60'>
                            <ng-template #template let-item>
                                <div style="display: inline-flex;">
                                    <button class="btn btn-outline-primary" (click)="onMostrarPdf(item)"
                                        title="Descargar"><i class="fa-solid fa-file-arrow-down"></i></button>
                                    <button class="btn btn-borrar-sm" (click)="Msjeliminar(item)" title="Borrar"
                                        [disabled]="!data.web_ajusta_expediente"></button>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field='descripcion' headerText='Descripción' width='200' minWidth='200'></e-column>
                        <e-column field='tabla' headerText='' width='0' minWidth='0'></e-column>
                        <e-column field='nombre_archivo' headerText='Nombre Archivo' width='200' minWidth='200'>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div class="col-md-6">
                <div class="p-4 p-md-2 mb-4 text-white rounded bg-dark">
                    <h5>Documentos por lista de verificación</h5>
                    <div class="mb-1 text-muted">
                        Documentos de todo tipo pero clasificados y exigidos para formar parte del expediente del
                        proveedor
                    </div>
                    <div style="position: absolute; top: 0px; right: 14px; font-size: 30px">
                        <i class="fa-solid fa-list-check"></i>
                    </div>
                </div>
                <ejs-grid [dataSource]='datalistArchivos' gridLines='Both' [toolbar]='toolbarOptions'
                    [allowSorting]='true' [allowResizing]='true' allowReordering='true' allowPaging='true'
                    [pageSettings]='initialPage' [rowHeight]='20'>
                    <e-columns>
                        <e-column field='id' headerText='' width='100'>
                            <ng-template #template let-item>
                                <div style="display: inline-flex;">
                                    <button class="btn btn-outline-primary" (click)="onMostrarPdf(item)"
                                        title="Descargar"><i class="fa-solid fa-file-arrow-down"></i></button>
                                    <button class="btn btn-nuevo-sm" (click)="NuevoListDocumento(item)" title="Agregar"
                                        [disabled]="!data.web_ajusta_check_list"></button>
                                    <button class="btn btn-borrar-sm" (click)="MsjeliminarListados(item)" title="Borrar"
                                        [disabled]="!data.web_ajusta_check_list"></button>
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field='descripcion' headerText='Descripción' width='200' minWidth='200'></e-column>
                        <e-column field='idsss' headerText='idsss' width='0' minWidth='0'></e-column>
                        <e-column field='tabla' headerText='' width='0' minWidth='0'></e-column>
                        <e-column field='nombre_archivo' headerText='Archivo' width='200' minWidth='200'></e-column>
                        <e-column field='obligatorio' headerText='Obligatorio' width='70' minWidth='70' type="boolean"
                            displayAsCheckBox='true' colorField="red" textAlign="center"></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </block-ui>
</div>

<ejs-dialog id="dlgfrmserie" #dlgfrmserie header='' width='400px' isModal='true' [visible]='false' showCloseIcon='true'>
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fa-duotone fa-info"></i> Información General.</div>
    </ng-template>
    <ng-template #content>
        <block-ui>
            <!-- formulario de Series de factura y nota credito -->
            <form [formGroup]="formserie">
                <br />
                <br />
                <div class="row" style="display: flex;">
                    <div *ngIf="this.type == 1" class="col-md-12">
                        <label><b>SERIE PARA LAS FACTURAS:</b></label>
                        <input type="text" formControlName="csd_serie_factura" class="form-control rounded-0">
                    </div>
                </div>
                <div class="row" style="display: flex;">
                    <div *ngIf="this.type == 2" class="col-md-12">
                        <label><b>FOLIO DE LAS FACTURAS:</b></label>
                        <input type="number" formControlName="csd_folio_factura" class="form-control rounded-0">
                    </div>
                </div>
                <div class="row" style="display: flex;">
                    <div *ngIf="this.type == 3" class="col-md-12">
                        <label><b>SERIE PARA NOTAS DE CRÉDITO:</b></label>
                        <input type="text" formControlName="csd_serie_notacredito" class="form-control rounded-0">
                    </div>
                </div>
                <div class="row" style="display: flex;">
                    <div *ngIf="this.type == 4" class="col-md-12">
                        <label><b>FOLIO PARA NOTAS DE CRÉDITO:</b></label>
                        <input type="number" formControlName="csd_folio_notacredito" class="form-control rounded-0">
                    </div>
                </div>
                <div class="row" style="display: flex;">
                    <div *ngIf="this.type == 5" class="col-md-12">
                        <label><b>SERIE DE PAGOS:</b></label>
                        <input type="text" formControlName="csd_serie_pago" class="form-control rounded-0">
                    </div>
                </div>
                <div class="row" style="display: flex;">
                    <div *ngIf="this.type == 6" class="col-md-12">
                        <label><b>FOLIO PARA PAGOS:</b></label>
                        <input type="number" formControlName="csd_folio_pago" class="form-control rounded-0">
                    </div>
                </div>
                <br />
                <br />
                <br />
            </form>
        </block-ui>
        <div class="callout callout-botones">
            <button type="button" class="btn btn-guardar rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                (click)="guardarSerie()" title="Almacenar Clave">
                Almacenar
            </button>
        </div>
    </ng-template>
</ejs-dialog>