import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableroResolver implements Resolve<boolean> {

  constructor(private http: HttpClient) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    // ====================================================================================
    // Cargamos los parametros proporcionados para el tablero, con ID de proveedor
    // ====================================================================================
    const parametro: HttpParams = new HttpParams().append('id', JSON.parse(localStorage.getItem('id')));
    return this.http.get<any>(`${environment.base_url}/tablero/proveedor`, { params: parametro });
  }
}
