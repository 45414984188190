<section>
    <div class="table-responsive">
        <table *ngIf="this.tb_abiertas" class="table table-hover table-bordered">
            <thead>
                <th style="min-width: 130px;">Folio</th>
                <th style="min-width: 100px;">Fecha</th>
                <th>Estatus</th>
                <th style="min-width: 100px;">Importe</th>
                <th>Moneda</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of tb_abiertas">
                    <td>
                        <div style="display: inline-flex; width: 100%;">
                            <div style="width: 80%">
                                <a [routerLink]="[ '/pages/cotizacion/', item.id ]">{{item.folio}}</a>
                            </div>
                            <div style="display: inline-flex;">
                                <button class="btn  btn-primary rounded-0 mb-2 mr-2" (click)="reportepdf(item)"
                                    title="Descarga Documento"><i class="fa-solid fa-file-arrow-down"></i></button>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{item.fecha}}
                    </td>
                    <td>
                        <div class="estatus" [innerHTML]="item.estatus | safeHtml">
                        </div>
                    </td>
                    <td style="text-align: right;">
                        {{item.importe | number: '.2-2'}}
                    </td>
                    <td>
                        {{item.moneda}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>

<ejs-dialog id="dlgcarga" #dlgcarga header='Sat' width='800px' isModal='true' [visible]='false' showCloseIcon='true'
    [open]="">
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fa-solid fa-file"></i> Archivo a subir
        </div>
    </ng-template>
    <ng-template #content>
        <div class="callout callout-primary">
            <div id='droparea' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
                <a href="" id="browse" (click)='browseClick()'><u>BuscarArchivo</u></a>
                <ejs-uploader id="archivoCarga" allowedExtensions={{formato}} [autoUpload]='false'
                    [dropArea]='archivoArea' multiple='false' (selected)="selected($event)">
                    <ng-template #template let-data>
                        <span class="fileListwrapper">
                            <span class="icon template-icons sf-icon-{{data.type}}"></span>
                            <span class="name file-name">{{data.name}}
                                ({{data.size}}bytes)</span>
                            <span class="upload-status">{{data.status}}</span>
                        </span>
                        <span class="e-icons e-file-remove-btn" title="Remove"></span>
                    </ng-template>
                </ejs-uploader>
            </div>
        </div>
        <div class="callout callout-botones">
            <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                (click)="subirPdfXml()" title="Almacenar Archivo">
                Subir Archivo
            </button>
        </div>
    </ng-template>
</ejs-dialog>