import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CotizacionService } from './cotizacion.service';

@Injectable({
  providedIn: 'root'
})
export class CotizacionResolver implements Resolve<boolean> {

  errores: any;
  constructor(
    private service: CotizacionService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {

    return forkJoin(
      this.service.lstarchivos(route.params['id']),
      this.service.getCotizacion(route.params['id'])
    );
  }
}
