import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { PagesModule } from './pages/pages.module';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { PagesComponent } from './pages/pages.component';
import { AuthModule } from './auth/auth.module';
import { dlgMensajeError } from './components/dlgMensajeError/dlgMensajeError.component';
import { dlgMensajeConfirma } from './components/dlgMensajeConfirma/dlgMensajeConfirma.component';
import { dlgMensaje } from './components/dlgMensaje/dlgMensaje.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    NopagefoundComponent,
    PagesComponent,
    dlgMensaje,
    dlgMensajeConfirma,
    dlgMensajeError,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AuthModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PagesModule
  ],
  providers: [
    //===============================================================================================
    // DatePipe se configura en esta seccion par afectar toda la aplicación
    { provide: DatePipe },
    //===============================================================================================
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
    //{ provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  //===============================================================================================
  // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
  // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
  //===============================================================================================
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  //===============================================================================================
})
export class AppModule { }
