import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import { ErroresService } from '../shared/errores.service';


@Injectable({
  providedIn: 'root',
})
export class listadoBDService {
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private errores: ErroresService
  ) { }

  public cargaListado(_usuario: string): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('_usuario', _usuario);
    return this.http.get<any>(`${environment.base_url}/auth/lst_BD`, {
      params: parametros,
    });
  }

  public cargaMenu_acceso(_usuario: string, _base: string): Observable<any> {
    const parametros: HttpParams = new HttpParams().append('_usuario', _usuario).append('_base',_base);
    return this.http.get<any>(`${environment.base_url}/auth/menu_acceso`, {
      params: parametros,
    });
  }

}
