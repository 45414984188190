<div class="modal-header">
    <h4 class="modal-title encabezado">Proveedor</h4>
</div>
<div class="modal-body">
    <div>
        <p>Capturar el nuevo precio del Producto.</p>
    </div>
    <div class="row">
        <label for="precio" class="form-label">Nuevo Precio</label>
        <input type="number" #precio class="form-control" id="precio" aria-describedby="preciolHelp">
        <div id="preciolHelp" class="form-text">El precio debe estar autorizado por el cliente.</div>
    </div>
</div>
<div class="modal-footer piepagina">
    <button type="button" class="btn boton btnAceptar" (click)="btnAceptar()">
        Aceptar
    </button>
    <button type="button" class="btn boton btnCancelar" autofocus aria-label="Close"
        (click)="activeModal.close('Cancelar')">
        Cancelar
    </button>
</div>