import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnModel, PageSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { SelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { comprasService } from './Services/compras.service';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-compras',
    templateUrl: './compras.component.html',
    styleUrls: ['./compras.component.scss']
})

export class ComprasComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    public tb_abiertas: any;
    public tb_cerradas: any;
    public tb_abiertas_nc: any;
    public tb_cerradas_nc: any;
    public tbEncabezado: Object = [
        { text: '<i class="fas fa-layer-group"></i> &nbsp;Abiertas', iconCss: '' },
        { text: '<i class="fas fa-window-restore"></i> &nbsp;Cerradas', iconCss: '' },
        { text: '<i class="fas fa-hand-holding-usd"></i> &nbsp;Finanzas', iconCss: '' },
        { text: '<i class="fas fa-folder"></i> &nbsp;Logistica', iconCss: '' }
    ];

    @ViewChild('dlgcarga') public dlgcarga: DialogComponent;

    public initialPage: PageSettingsModel;
    public data: any;
    public tb_productos: any;
    public toolbarOptions: ToolbarItems[];
    public _ID_Proveedor: number = -1;
    public _IDdocumento: number = -1;
    public columna: string = '';

    /********************************
     * @variable    archivos => cargamos el archivo imagen o documento que subiremos al registro
     * @variable archivoArea => esta variable identifica el drop area
     * @variable     dropEle => activa esa funcion
     * @variable   Id_Imagen => varibale de tipo numero, asignamos el id del registro y asi asignar la imagen
     * @variable Id_Propiedades => varibale de tipo numero, asignamos el id del registro y asi asignar las propiedades o elimnar las propiedades
     * 
     */
    // variables de imagen
    private archivos: UploaderComponent;
    public archivoArea: HTMLElement;
    public Id_Imagen: number = -1;
    public Id_Propiedades: number = -1;
    public ItemImgaen: any;
    public formato: string = '';

    constructor(
        private service: comprasService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private http: HttpClient,
    ) {
        this.route.data.subscribe((resp: { listado: any[] }) => {
            let respuesta = JSON.parse(JSON.stringify(resp.listado)).respuesta;
            this.tb_abiertas = respuesta.abiertas;
            this.tb_cerradas = respuesta.cerradas;
            this.tb_abiertas_nc = respuesta.abiertas_nc;
            this.tb_cerradas_nc = respuesta.cerradas_nc;
        });
    }

    ngOnInit(): void {
        //************************************************************************************************************************************ */
        // Grid: initialPage => Configuración configuramos a 7 Registros al grid
        this.initialPage = { pageSizes: true, pageSize: 10 };
        this.toolbarOptions = ['Search'];
    }

    /**
     * Metodo de la seleccion de los TABS.
     */
    public select(e: any) {
        // if (e.isSwiped) {
        //   e.cancel = true;
        // }
        if (e.selectingIndex == 0) {

        } else if ((e.selectingIndex == 1)) {

        }
    }

    /**********************************************************************************************************************************************************
     * 
     * Funcionalidad de la pagina
     *  
     ***********************************************************************************************************************************************************/
    /**
     * 
     * @param id pasamos el id del documento para subir o descargar el archivo
     * @param col pasamos ls columna para saber donde ajustaremos el archivo
     */
    public Subirdoc(args: any, col: string, formt: string) {
        this._IDdocumento = args.id;
        this.formato = formt;
        this.columna = col;
        this.archivoArea = document.getElementById('droparea');
        this.dlgcarga.show();
    }

    public subirPdfXml() {
        if (this.archivos == undefined) {
            DialogUtility.alert({
                title: 'Información Arcarius [Alerta] <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>No has seleccionado un archivo</b>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
            return;
        }
        else {
            // si el formato es igual a XML mandamos validar el formato
            if (this.formato == '.xml') {
                this.dlgcarga.hide();
                let formulario = new FormData();
                formulario.append('archivo', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
                formulario.append('Content-Type', 'application/json');
                formulario.append('Accept', `application/json`);
                this.blockUI.start('trabajando...');
                this.http.post(`${environment.base_url}/proveedor/compra/XmlValidar/${this._IDdocumento}/${this.columna}`, formulario).subscribe(resp => {
                    let respuesta = JSON.parse(JSON.stringify(resp));
                    if (respuesta.numero < 0) {
                        this.blockUI.stop();
                        DialogUtility.alert({
                            title: 'Informacion Arcarius [Problemas]',
                            content: respuesta.mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    } else {
                        //==============================================================================
                        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                        //==============================================================================
                        this.service.cargaDocumentos().subscribe((resp) => {
                            this.formato = '.pdf';
                            this.columna = 'f_pdf';
                            this.archivoArea = document.getElementById('droparea');
                            this.dlgcarga.show();
                            //this.tb_cerradas = resp.respuesta.cerradas;
                            //this.tb_abiertas = resp.respuesta.abiertas;
                        });
                        this.dlgcarga.hide();
                        this.blockUI.stop();
                    }
                });

            } else if (this.formato == '.pdf') {
                //************************************************************* */
                //? en caso de ser PDF subirmos sin ninguna validacion  adicional
                //************************************************************* */        
                let formulario = new FormData();
                formulario.append('imagen', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
                formulario.append('Content-Type', 'application/json');
                formulario.append('Accept', `application/json`);
                this.blockUI.start('trabajando...');
                this.http.post(`${environment.base_url}/proveedor/compra/subirArchivo/${this._IDdocumento}/${this.columna}`, formulario).subscribe(resp => {
                    let respuesta = JSON.parse(JSON.stringify(resp));
                    if (respuesta.numero < 0) {
                        this.blockUI.stop();
                        DialogUtility.alert({
                            title: 'Informacion Arcarius [Problemas]',
                            content: respuesta.mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    } else {
                        //==============================================================================
                        // Cargamos nuevamente la informacion de para ver la imagen
                        //==============================================================================
                        // recargamos si requerimos recargar la imagen cargar callback aqui 
                        //==============================================================================
                        // Utilizacion de "ej2_instances" para poder ejecutar un metodo dentro del componente
                        // Este componente no se utilizo por ViewChild, ya que no se renderiza al inicio
                        //==============================================================================
                        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                        //==============================================================================
                        this.service.cargaDocumentos().subscribe((resp) => {
                            this.tb_cerradas = resp.respuesta.cerradas;
                            this.tb_abiertas = resp.respuesta.abiertas;
                        });
                        this.blockUI.stop();
                        this.dlgcarga.hide();
                    }
                });
            }
        }
    }

    /**
     * 
     * @param id pasamos el id del documento para subir o descargar el archivo
     */
    //****************************************************************************************************************************************************************************************************** */
    //**                      Metodos de la Imagen:                                                                                                                                                     */
    //****************************************************************************************************************************************************************************************************** */
    /**
     * Identificamos el componente de area de soltar, que será utilizada para arrastrar los archivos
     * y soltarlos.
     * Se tiene que haces este truco, ya que el componente de uploader se renderiza hasta que el dialog
     * se carga o visualiza la primera vez.
     * Asi mismo ocultamos el componente con el boton de "browser" y su área, de esta manera
     * dejamos solo el div personalizado donde se arrastra y el link paa abrir el buscador de archivos
     * 
     * Esta se dispara cuando seleccionamos la pestña numero 1 (comenzamos a contar desde el Cero)
     * 
     * @param e No utilizado por el momento
     * 
     * 
     * se importa esta propiedad
     * 
        import { SelectEventArgs } from '@syncfusion/ej2-navigations';
     */
    /********************************
     * 
     * @method handleSelectEvent => funcionalidad del froparea este metodo activa el drop area
     * 
     * @method       browseClick => Abre el explorador de archivos para seleccionr una imagen/archivo
     * 
     * @method          selected => asigna el valor del archivo en la variable archivo
     * @variable         archivo => contiene la imagen/archivo encriptado
     * 
     * @method    guardarArchivo => metodo almacena el imagen/archivo en la tabla que corresponde
     * 
     * 
     * 
     */

    public handleSelectEvent(e: SelectEventArgs): void {
        if (e.selectedIndex === 2) {
            this.archivoArea = document.getElementById('droparea');
        }
    }

    // imagen
    public browseClick() {
        document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
    }
    public selected(args: UploaderComponent) {
        this.archivos = args;
    }
    //****************************************************************************************************************************************************************************************************** */
    //**                      Metodos Descarga de Archivos:                                                                                                                                                     */
    //****************************************************************************************************************************************************************************************************** */
    public onMostrarXml(args: any, col: string, folio: string, id: number): void {
        let nombrearchivo: string;
        switch (id) {
            case 1:
                nombrearchivo = 'XML ' + folio;
                break;
            case 2:
                nombrearchivo = 'PDF ' + folio;
                break;
            case 3:
                nombrearchivo = 'PAGO XML ' + folio;
                break;
            case 4:
                nombrearchivo = 'PAGO PDF ' + folio;
                break;
            case 5:
                nombrearchivo = 'Comprobante XML ' + folio;
                break;
            default:
                // console.log('no hacemos nada');
                return
        }
        // ================================================================================
        // Ejecutamos el reporte con los parametros proporcionados
        // ================================================================================
        this.blockUI.start('trabajando...');
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        this.http.post<any>(`${environment.base_url}/proveedor/compra/descargarDoc/${args.id}/${col}`, {})
            .subscribe(
                response => {
                    this.blockUI.stop();
                    // ==========================================================================
                    // decodificacion => decodificamos el json que recibimos en response
                    // la funcion es "decodeURIComponent" la traemos con el codificado atob
                    // simplemente agregamos decodeURIComponent para devolver el xml sin caracteres especiales
                    // respetando la codificacion UTF - 8
                    // ==========================================================================
                    let byteArray = decodeURIComponent(escape(window.atob(response.archivo)));
                    // Tipo se coloca =>  "application/xml": para reconocerlo como archivo xml archivo de salida
                    let blob: any = new Blob([byteArray], { type: 'application/xml' });

                    // ***********************************************
                    // comentamos esta linea para no abrir el navegador
                    // ***********************************************
                    //const url = window.URL.createObjectURL(blob);
                    /**
                     * agregamos este pequeño codigo para descargar el xml
                     */
                    const element = document.createElement('a');
                    element.href = window.URL.createObjectURL(blob);
                    element.download = nombrearchivo;//this.dataInfo.folio;
                    element.click();
                    // fialiizamos la descarga del archivo
                    // const url = window.URL.createObjectURL(blob);
                    // window.open(url, '_blank');
                    // console.log(byteArray);
                },
                err => {
                    let error: number = err.status;
                    switch (error) {
                        case 500:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error tu archivo aun no existe, favor de revisar con Recursos Humanos',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        case 404:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a Dpt Sistemas',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        default:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a RH',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                    }
                }
            );
    }

    private _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public onMostrarPdf(args: any, col: string, folio: string, id: number): void {
        let nombrearchivo: string;
        switch (id) {
            case 1:
                nombrearchivo = 'XML ' + folio;
                break;
            case 2:
                nombrearchivo = 'PDF ' + folio;
                break;
            case 3:
                nombrearchivo = 'PAGO XML ' + folio;
                break;
            case 4:
                nombrearchivo = 'PAGO PDF ' + folio;
                break;
            case 5:
                nombrearchivo = 'Comprobante XML ' + folio;
                break;
            default:
                // console.log('no hacemos nada');
                return
        }
        // ================================================================================
        // Ejecutamos el reporte con los parametros proporcionados
        // ================================================================================
        this.blockUI.start('trabajando...');
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        this.http.post<any>(`${environment.base_url}/proveedor/compra/descargarDoc/${args.id}/${col}`, {})
            .subscribe(
                response => {
                    if (response.numero > 0) {
                        // ==========================================================================
                        // decodificacion => decodificamos el json que recibimos en response
                        // la funcion es "decodeURIComponent" la traemos con el codificado atob
                        // simplemente agregamos decodeURIComponent para devolver el pdf sin caracteres especiales
                        // respetando la codificacion UTF - 8
                        // ==========================================================================
                        let byteArray = this._base64ToArrayBuffer(response.archivo);
                        // Tipo se coloca =>  "application/xml": para reconocerlo como archivo pdf archivo de salida
                        let blob: any = new Blob([byteArray], { type: 'application/pdf; charset=utf-8' });
                        /**
                        * agregamos este pequeño codigo para descargar el xml
                        */
                        const element = document.createElement('a');
                        element.href = window.URL.createObjectURL(blob);
                        element.download = nombrearchivo;//this.dataInfo.folio;
                        element.click();
                        // const url = window.URL.createObjectURL(blob);
                        // window.open(url, '_blank');
                        this.blockUI.stop();
                    } else {
                        DialogUtility.alert({
                            title: response.titulo,
                            content: response.mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    }
                },
                err => {
                    this.blockUI.stop();
                    let error: number = err.status;
                    switch (error) {
                        case 500:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error tu archivo aun no existe, favor de revisar con Recursos Humanos',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        case 404:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a Dpt Sistemas',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        default:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a RH',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                    }
                    this.blockUI.stop();
                }
            );
    }

    /**
     * Metodo para generar la descarga del documento de compra
     * @param item Data que corresponde al renglo seleccionado, para lo cual se requiere el ID y Folio del documento
     */
    public reportepdf(item: any): void {
        console.log(item);
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/pdf" });
        this.http.post<any>(`${environment.base_url}/proveedor/compra/reportepdf/${item.id}`, {}, { responseType: resonseType, headers: headers }).subscribe(
            response => {
                let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
                const url = window.URL.createObjectURL(blob);
                const element = document.createElement('a');
                element.href = window.URL.createObjectURL(blob);
                element.download = item.folio + '.pdf';;
                element.click();
                this.blockUI.stop();
            }
        );
    }

    public comprobantepago(item: any) {
        console.log(item);
        this.blockUI.start('trabajando...');
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        this.http.post<any>(`${environment.base_url}/proveedor/compra/comprobantepago/${item.comprobante}`, {})
            .subscribe(
                response => {
                    if (response.numero > 0) {
                        let byteArray = this._base64ToArrayBuffer(response.archivo);
                        let blob: any = new Blob([byteArray], { type: 'application/pdf; charset=utf-8' });
                        const element = document.createElement('a');
                        element.href = window.URL.createObjectURL(blob);
                        element.download = 'Pago_' + item.folio;
                        element.click();
                        this.blockUI.stop();
                    } else {
                        DialogUtility.alert({
                            title: response.titulo,
                            content: response.mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    }
                },
                err => {
                    this.blockUI.stop();
                    let error: number = err.status;
                    switch (error) {
                        case 500:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error tu archivo aun no existe, favor de revisar con Recursos Humanos',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        case 404:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a Dpt Sistemas',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        default:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a RH',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                    }
                    this.blockUI.stop();
                }
            );
    }


    /**
     * Metodo para identificar evento cuando se cambia el TAB
     * @param args 
     * @returns 
     */
    public beforeChange(args: NgbPanelChangeEvent) {
        if (args.nextState) {
            if (args.panelId == 'pnAbiertas') {

            } else if (args.panelId == 'pnCerradas') {

            } else if (args.panelId == 'pnDevAbiertas') {

            } else if (args.panelId == 'pnDevCerradas') {

            }
        }
    }

}
