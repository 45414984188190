<div class="row main">
  <div class="col-md-6">
    <div class="left_main">

      <div class="left_bg_center">
        <img src="/assets/images/logo-text.png" alt="Arcarius ERP logo">
        <div class="cal_first">
          <h5>
            Las victorias se ganan formando alianzas.
          </h5>
        </div>
      </div>
      <div class="left-bg-footer">
        <h6>
          Que hay de nuevo !
        </h6>
        <p>
          Requisiciones de Materiales y Ordenes de Compra, disponibles en tableros.
        </p>
        <a target="_blank" href="https://arcariuserp.com">Necesito mas información</a>
      </div>

    </div>
  </div>
  <div class="col-md-6">
    <div class="columns">
      <div class="panel singup-panel">
        <h5 class="light-font-weight with-follow-on">
          <b>Bienvenido de nuevo.</b>
        </h5>
        <p>Ingresa tu información de acceso</p>
        <form class="login-form" autocomplete="off" [formGroup]="loginForm" (ngSubmit)="lstBD()">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <i class="fas fa-user login-icono"></i>
                <label for="tfUsuario">Usuario</label>
                <input type="text" class="form-control" id="tfUsuario" aria-describedby="emailHelp"
                  placeholder="Nombre de usuario" formControlName="user">
                <small id="emailHelp" class="form-text text-muted">Nunca compartas tu acceso.</small>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <i class="fas fa-key login-icono"></i>
                <label for="tfPassword">Contraseña</label>
                <input type="password" class="form-control" id="tfPassword" placeholder="Contraseña"
                  formControlName="password">
                <small id="emailHelp" class="form-text text-muted">Cambia de manera regular tu contraseña.</small>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-4">
              <button type="submit" class="btn btn-primary btn-ingreso">
                <i class="fa-solid fa-user-check"></i>
                Ingresar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ejs-dialog id="dlgBD" #dlgBD header='Empresas disponibles' width='600px' height="300px" isModal='true'
  [visible]='false' showCloseIcon='true'>
  <ng-template #header>
    <div id="dlg-template" class="e-icon-settings"><i class="fas fa-search"></i> Seleccionar la empresa</div>
  </ng-template>
  <ng-template #content>
    <block-ui>
      <ejs-grid [dataSource]='listaDB' (rowSelected)="onRowSelected($event)">
        <e-columns>
          <e-column field='id' headerText='No.' width='0' textAlign='Right'></e-column>
          <e-column field='base' headerText='Empresas' width='200'></e-column>
        </e-columns>
      </ejs-grid>
    </block-ui>
  </ng-template>
</ejs-dialog>
