import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErroresService } from 'src/app/shared/errores.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CotizacionesService {

  constructor(

    private http: HttpClient,
    private errores: ErroresService

  ) { }
  /**
   * 
   * @returns retorna las cotizaciones en Array de json para abiertas y cerradas
   */
  public Tbcotizaciones(): Observable<any> {
    return this.http
      .get(`${environment.base_url}/proveedor/cotizaciones/Tbcotizaciones`)
      .pipe(
        catchError(error => {
          return throwError(this.errores.getErrores(error));
        })
      );
  }

}
