import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MdlPrecio } from '../Models/MdlPrecio';

@Injectable({
	providedIn: 'root'
})
export class ProductoService {

	constructor(
		private http: HttpClient
	) { }

	//*************************************************************************************************************************************/
	//*                   Carga Resolver                                                                                                      */
	//********************************************************************************************************************************* */
	public cargaProducto(): Observable<any> {
		return this.http.get(`${environment.base_url}/proveedor/producto/Conf/${localStorage.getItem('id')}`);
	}
	//*************************************************************************************************************************************/
	//*                     CRUD DE LA PAGINA                                                                                                      */
	//********************************************************************************************************************************* */

	/**
	 * Metodo para guardar precio
	 * @param modelo 
	 * @returns 
	 */
	public GuardarPrecio(modelo: MdlPrecio): Observable<any> {
		return this.http.post(`${environment.base_url}/proveedor/producto/GuardarPrecio`, modelo);
	}
}