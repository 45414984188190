<block-ui>
</block-ui>

<section>

    <div class="table-responsive" style="height: calc(100vh - 200px);">
        <table *ngIf="this.tb_abiertas" class="table table-hover table-bordered">
            <thead>
                <th style="min-width: 170px;">Folio</th>
                <th style="min-width: 100px;">Fecha</th>
                <th style="min-width: 100px;">Entrega</th>
                <th style="min-width: 100px;">Recibido</th>
                <th>Pago Programado</th>
                <th>Estatus</th>
                <th style="min-width: 100px;">SubTotal</th>
                <th style="min-width: 100px;">Impuesto</th>
                <th style="min-width: 100px;">Total</th>
                <th>Moneda</th>
                <th>Tipo Cambio</th>
                <th style="min-width: 250px;">Empleado</th>
                <th style="min-width: 200px;">Sucursal</th>
                <th>Factura XML</th>
                <th>Factura PDF</th>
                <th style="min-width: 100px;">Referencia</th>
                <th style="min-width: 280px;">UUID</th>
                <th style="min-width: 100px;">Total Factura</th>
                <th style="min-width: 250px;">Uso CFDI</th>
                <th style="min-width: 200px;">Forma Pago</th>
                <th style="min-width: 250px;">Metodo Pago</th>
                <th style="min-width: 100px;">Pago</th>
                <th style="min-width: 70px;">Pago XML</th>
                <th style="min-width: 70px;">Pago PDF</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of tb_abiertas">
                    <td>
                        <div style="display: inline-flex; width: 100%;">
                            <div style="width: 80%">
                                <a [routerLink]="[ '/pages/compra/', item.id ]">{{item.folio}}</a>
                            </div>
                            <div style="display: inline-flex;">
                                <button class="btn  btn-primary rounded-0 mb-2 mr-2" (click)="reportepdf(item)"
                                    title="Descarga Documento"><i class="fa-solid fa-file-arrow-down"></i></button>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{item.fecha}}
                    </td>
                    <td>
                        {{item.fecha_entrega}}
                    </td>
                    <td>
                        {{item.fecha_recibido}}
                    </td>
                    <td>
                        {{item.fecha_pago}}
                    </td>
                    <td>
                        <div class="estatus" [innerHTML]="item.estatus | safeHtml">
                        </div>
                    </td>
                    <td style="text-align: right;">
                        {{item.subtotal | number: '.2-2'}}
                    </td>
                    <td style="text-align: right;">
                        {{item.impuesto | number: '.2-2'}}
                    </td>
                    <td style="text-align: right;">
                        {{item.total | number: '.2-2'}}
                    </td>
                    <td>
                        {{item.moneda}}
                    </td>
                    <td>
                        {{item.tc_general}}
                    </td>
                    <td>
                        {{item.empleado}}
                    </td>
                    <td>
                        {{item.sucursal}}
                    </td>
                    <td>
                        <div style="display: inline-flex;">
                            <button *ngIf="item.sube_factura && !item.factura_xml" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                (click)="Subirdoc(item,'f_xml','.xml')" title="Subir XML"><i
                                    class="fa-solid fa-file-arrow-up"></i></button>
                            <button *ngIf="item.factura_xml" class="btn btn-success rounded-0 mb-2 mr-2"
                                (click)="onMostrarXml(item,'f_xml',item.folio, 1)" title="Descargar"><i
                                    class="fa-solid fa-file-arrow-down"></i></button>
                        </div>
                    </td>
                    <td>
                        <div style="display: inline-flex;">
                            <button *ngIf="item.sube_factura && !item.factura_pdf" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                (click)="Subirdoc(item,'f_pdf','.pdf')" title="Subir PDF"><i
                                    class="fa-solid fa-file-arrow-up"></i></button>
                            <button *ngIf="item.factura_pdf" class="btn btn-success rounded-0 mb-2 mr-2"
                                (click)="onMostrarPdf(item,'f_pdf',item.folio, 2)" title="Descargar"><i
                                    class="fa-solid fa-file-arrow-down"></i></button>
                        </div>
                    </td>
                    <td>
                        {{item.referencia}}
                    </td>
                    <td>
                        {{item.uuid}}
                    </td>
                    <td style="text-align: right;">
                        {{item.uuid_total | number: '.2-2'}}
                    </td>
                    <td>
                        {{item.uso}}
                    </td>
                    <td>
                        {{item.formapago}}
                    </td>
                    <td>
                        {{item.metodopago}}
                    </td>
                    <td>
                        <div style="display: inline-flex;">
                            <button *ngIf="item.comprobante > 0" class="btn btn-success rounded-0 mb-2 mr-2"
                                (click)="comprobantepago(item)" title="Descargar"><i
                                    class="fa-solid fa-file-arrow-down"></i></button>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                        </div>
                        <ng-template #thenBlock>
                            <div style="display: inline-flex;" *ngIf="">
                                <button *ngIf="!item.pago_xml" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                    (click)="Subirdoc(item,'p_xml','.xml')" title="Subir XML"><i
                                        class="fa-solid fa-file-arrow-up"></i></button>
                                <button *ngIf="item.pago_xml" class="btn btn-success rounded-0 mb-2 mr-2"
                                    (click)="onMostrarXml(item,'p_xml',item.folio, 3)" title="Descargar"><i
                                        class="fa-solid fa-file-arrow-down"></i></button>
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>
                            NA
                        </ng-template>
                    </td>
                    <td>
                        <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                        </div>
                        <ng-template #thenBlock>
                            <div style="display: inline-flex;">
                                <button *ngIf="!item.pago_pdf" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                    (click)="Subirdoc(item,'p_pdf','.pdf')" title="Subir PDF"><i
                                        class="fa-solid fa-file-arrow-up"></i></button>
                                <button *ngIf="item.pago_pdf" class="btn btn-success rounded-0 mb-2 mr-2"
                                    (click)="onMostrarPdf(item,'p_pdf',item.folio, 4)" title="Descargar"><i
                                        class="fa-solid fa-file-arrow-down"></i></button>
                            </div>
                        </ng-template>
                        <ng-template #elseBlock>
                            NA
                        </ng-template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div style="margin-top: 40px;">
        <ngb-accordion #acordion="ngbAccordion" [collapsed]="false" (panelChange)="beforeChange($event)">
            <ngb-panel title="Compras Cerradas" id="pnCerradas">
                <ng-template ngbPanelContent>
                    <ejs-grid [dataSource]='tb_cerradas' gridLines='Both' [toolbar]='toolbarOptions'
                        [allowSorting]='true' [allowResizing]='true' allowReordering='true' allowPaging='true'
                        [pageSettings]='initialPage' [rowHeight]='20'>
                        <e-columns>
                            <e-column field='id' headerText='ID' width='0'>
                            </e-column>
                            <e-column field='folio' headerText='Folio' width='150'>
                                <ng-template #template let-item>
                                    <div style="display: inline-flex; width: 100%;">
                                        <div style="width: 80%">{{item.folio}}</div>
                                        <div style="display: inline-flex;">
                                            <button class="btn btn-primary rounded-0 mb-2 mr-2"
                                                (click)="reportepdf(item)" title="Descarga Documento"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='fecha' headerText='Fecha' width='80' [allowEditing]='false'>
                            </e-column>
                            <e-column field='fecha_entrega' headerText='Entrega' width='80'
                                [allowEditing]='false'></e-column>
                            <e-column field='fecha_recibido' headerText='Recibido' width='80'
                                [allowEditing]='false'></e-column>
                            <e-column field='fecha_pago' headerText='Pago Programado' width='120'
                                [allowEditing]='false'></e-column>
                            <e-column field='estatus' headerText='Estatus' width='150' [allowEditing]='false'>
                                <ng-template #template let-item>
                                    <div class="estatus" [innerHTML]="item.estatus | safeHtml">
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='subtotal' headerText='Subtotal' width='80' textAlign="right" format='N2'
                                [allowEditing]='false'>
                            </e-column>
                            <e-column field='impuesto' headerText='Impuesto' width='80' textAlign="right" format='N2'
                                [allowEditing]='false'>
                            </e-column>
                            <e-column field='total' headerText='Total' width='90' textAlign="right" format='N2'
                                [allowEditing]='false'>
                            </e-column>
                            <e-column field='moneda' headerText='Moneda' width='70'></e-column>
                            <e-column field='tc_general' headerText='Tipo Cambio' width='90'>
                            </e-column>
                            <e-column field='empleado' headerText='Empleado' width='150' minWidth="150">
                            </e-column>
                            <e-column field='sucursal' headerText='Sucursal' width='150'></e-column>
                            <e-column field='factura_xml' headerText='Factura XML' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="onMostrarXml(item,'f_xml',item.folio, 1)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='factura_pdf' headerText='Factura PDF' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="onMostrarPdf(item,'f_pdf',item.folio, 2)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='uuid' headerText='UUID' width='300'></e-column>
                            <e-column field='uuid_total' headerText='Total Factura' width='100' textAlign="right"
                                format='N2'>
                            </e-column>
                            <e-column field='uso' headerText='Uso CFDI' width='150'></e-column>
                            <e-column field='formapago' headerText='Forma Pago' width='150'>
                            </e-column>
                            <e-column field='metodopago' headerText='Metodo Pago' width='150'>
                            </e-column>

                            <e-column field='comprobante' headerText='Pago' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button *ngIf="item.comprobante > 0" class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="comprobantepago(item)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column headerText='Pago XML' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div style="display: inline-flex;" *ngIf="">
                                            <button class="btn btn-success rounded-0 mb-2 mr-2"
                                                (click)="onMostrarXml(item,'p_xml',item.folio, 3)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        NA
                                    </ng-template>
                                </ng-template>
                            </e-column>
                            <e-column field='pago_pdf' headerText='Pago PDF' width='93' textAlign="center">
                                <ng-template #template let-item>
                                    <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div style="display: inline-flex;">
                                            <button class="btn btn-success rounded-0 mb-2 mr-2"
                                                (click)="onMostrarPdf(item,'p_pdf',item.folio, 4)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        NA
                                    </ng-template>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Devoluciones Abiertas" id="pnDevAbiertas">
                <ng-template ngbPanelContent>
                    <ejs-grid [dataSource]='tb_abiertas_nc' gridLines='Both' [toolbar]='toolbarOptions'
                        [allowSorting]='true' allowReordering='true' allowPaging='true' [pageSettings]='initialPage'
                        [rowHeight]='20'>
                        <e-columns>
                            <e-column field='id' headerText='ID' width='0'>
                            </e-column>
                            <e-column field='folio' headerText='Folio' width='150'>
                                <ng-template #template let-item>
                                    <div style="display: inline-flex; width: 100%;">
                                        <div style="width: 80%">{{item.folio}}</div>
                                        <div style="display: inline-flex;">
                                            <button class="btn  btn-primary rounded-0 mb-2 mr-2"
                                                (click)="reportepdf(item)" title="Descarga Documento"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='fecha' headerText='Fecha' width='80' [allowEditing]='false'>
                            </e-column>
                            <e-column field='fecha_entrega' headerText='Entrega' width='80'
                                [allowEditing]='false'></e-column>
                            <e-column field='fecha_recibido' headerText='Recibido' width='80'
                                [allowEditing]='false'></e-column>
                            <e-column field='fecha_pago' headerText='Pago Programado' width='120'
                                [allowEditing]='false'></e-column>
                            <e-column field='estatus' headerText='Estatus' width='150' [allowEditing]='false'>
                                <ng-template #template let-item>
                                    <div class="estatus" [innerHTML]="item.estatus | safeHtml">
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='subtotal' headerText='Subtotal' width='80' textAlign="right" format='N2'
                                [allowEditing]='false'></e-column>
                            <e-column field='impuesto' headerText='Impuesto' width='80' textAlign="right" format='N2'
                                [allowEditing]='false'></e-column>
                            <e-column field='total' headerText='Total' width='90' textAlign="right" format='N2'
                                [allowEditing]='false'></e-column>
                            <e-column field='moneda' headerText='Moneda' width='70'></e-column>
                            <e-column field='tc_general' headerText='Tipo Cambio' width='90'>
                            </e-column>
                            <e-column field='empleado' headerText='Empleado' width='150' minWidth="150">
                            </e-column>
                            <e-column field='sucursal' headerText='Sucursal' width='150'></e-column>
                            <e-column field='factura_xml' headerText='Factura XML' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button *ngIf="!item.factura_xml" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                            (click)="Subirdoc(item,'f_xml','.xml')" title="Subir XML"><i
                                                class="fa-solid fa-file-arrow-up"></i></button>
                                        <button *ngIf="item.factura_xml" class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="onMostrarXml(item,'f_xml',item.folio, 1)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='factura_pdf' headerText='Factura PDF' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button *ngIf="!item.factura_pdf" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                            (click)="Subirdoc(item,'f_pdf','.pdf')" title="Subir PDF"><i
                                                class="fa-solid fa-file-arrow-up"></i></button>
                                        <button *ngIf="item.factura_pdf" class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="onMostrarPdf(item,'f_pdf',item.folio, 2)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='uuid' headerText='UUID' width='300'></e-column>
                            <e-column field='uuid_total' headerText='Total Factura' width='100' textAlign="right"
                                format='N2'>
                            </e-column>
                            <e-column field='uso' headerText='Uso CFDI' width='150'></e-column>
                            <e-column field='formapago' headerText='Forma Pago' width='150'>
                            </e-column>
                            <e-column field='metodopago' headerText='Metodo Pago' width='150'>
                            </e-column>

                            <e-column field='comprobante' headerText='Pago' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button *ngIf="item.comprobante > 0" class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="comprobantepago(item)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column headerText='Pago XML' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div style="display: inline-flex;" *ngIf="">
                                            <button *ngIf="!item.pago_xml" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                                (click)="Subirdoc(item,'p_xml','.xml')" title="Subir XML"><i
                                                    class="fa-solid fa-file-arrow-up"></i></button>
                                            <button *ngIf="item.pago_xml" class="btn btn-success rounded-0 mb-2 mr-2"
                                                (click)="onMostrarXml(item,'p_xml',item.folio, 3)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        NA
                                    </ng-template>

                                </ng-template>
                            </e-column>
                            <e-column field='pago_pdf' headerText='Pago PDF' width='93' textAlign="center">
                                <ng-template #template let-item>
                                    <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div style="display: inline-flex;">
                                            <button *ngIf="!item.pago_pdf" class="btn  btn-danger rounded-0 mb-2 mr-2"
                                                (click)="Subirdoc(item,'p_pdf','.pdf')" title="Subir PDF"><i
                                                    class="fa-solid fa-file-arrow-up"></i></button>
                                            <button *ngIf="item.pago_pdf" class="btn btn-success rounded-0 mb-2 mr-2"
                                                (click)="onMostrarPdf(item,'p_pdf',item.folio, 4)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        NA
                                    </ng-template>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Devoluciones Cerradas" id="pnDevCerradas">
                <ng-template ngbPanelContent>
                    <ejs-grid [dataSource]='tb_cerradas_nc' gridLines='Both' [toolbar]='toolbarOptions'
                        [allowSorting]='true' [allowResizing]='true' allowReordering='true' allowPaging='true'
                        [pageSettings]='initialPage' [rowHeight]='20'>
                        <e-columns>
                            <e-column field='id' headerText='ID' width='0'>
                            </e-column>
                            <e-column field='folio' headerText='Folio' width='150'>
                                <ng-template #template let-item>
                                    <div style="display: inline-flex; width: 100%;">
                                        <div style="width: 80%">{{item.folio}}</div>
                                        <div style="display: inline-flex;">
                                            <button class="btn  btn-primary rounded-0 mb-2 mr-2"
                                                (click)="reportepdf(item)" title="Descarga Documento"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='fecha' headerText='Fecha' width='80' [allowEditing]='false'>
                            </e-column>
                            <e-column field='fecha_entrega' headerText='Entrega' width='80'
                                [allowEditing]='false'></e-column>
                            <e-column field='fecha_recibido' headerText='Recibido' width='80'
                                [allowEditing]='false'></e-column>
                            <e-column field='fecha_pago' headerText='Pago Programado' width='120'
                                [allowEditing]='false'></e-column>
                            <e-column field='estatus' headerText='Estatus' width='150' [allowEditing]='false'>
                                <ng-template #template let-item>
                                    <div class="estatus" [innerHTML]="item.estatus | safeHtml">
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='subtotal' headerText='Subtotal' width='80' textAlign="right" format='N2'
                                [allowEditing]='false'>
                            </e-column>
                            <e-column field='impuesto' headerText='Impuesto' width='80' textAlign="right" format='N2'
                                [allowEditing]='false'>
                            </e-column>
                            <e-column field='total' headerText='Total' width='90' textAlign="right" format='N2'
                                [allowEditing]='false'>
                            </e-column>
                            <e-column field='moneda' headerText='Moneda' width='70'></e-column>
                            <e-column field='tc_general' headerText='Tipo Cambio' width='90'>
                            </e-column>
                            <e-column field='empleado' headerText='Empleado' width='150' minWidth="150">
                            </e-column>
                            <e-column field='sucursal' headerText='Sucursal' width='150'></e-column>
                            <e-column field='factura_xml' headerText='Factura XML' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="onMostrarXml(item,'f_xml',item.folio, 1)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='factura_pdf' headerText='Factura PDF' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="onMostrarPdf(item,'f_pdf',item.folio, 2)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='uuid' headerText='UUID' width='300'></e-column>
                            <e-column field='uuid_total' headerText='Total Factura' width='100' textAlign="right"
                                format='N2'>
                            </e-column>
                            <e-column field='uso' headerText='Uso CFDI' width='150'></e-column>
                            <e-column field='formapago' headerText='Forma Pago' width='150'>
                            </e-column>
                            <e-column field='metodopago' headerText='Metodo Pago' width='150'>
                            </e-column>

                            <e-column field='comprobante' headerText='Pago' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div style="display: inline-flex;">
                                        <button *ngIf="item.comprobante > 0" class="btn btn-success rounded-0 mb-2 mr-2"
                                            (click)="comprobantepago(item)" title="Descargar"><i
                                                class="fa-solid fa-file-arrow-down"></i></button>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column headerText='Pago XML' width='90' textAlign="center">
                                <ng-template #template let-item>
                                    <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div style="display: inline-flex;" *ngIf="">
                                            <button class="btn btn-success rounded-0 mb-2 mr-2"
                                                (click)="onMostrarXml(item,'p_xml',item.folio, 3)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        NA
                                    </ng-template>
                                </ng-template>
                            </e-column>
                            <e-column field='pago_pdf' headerText='Pago PDF' width='93' textAlign="center">
                                <ng-template #template let-item>
                                    <div *ngIf="item.metodopago.startsWith('PPD'); then thenBlock else elseBlock">
                                    </div>
                                    <ng-template #thenBlock>
                                        <div style="display: inline-flex;">
                                            <button class="btn btn-success rounded-0 mb-2 mr-2"
                                                (click)="onMostrarPdf(item,'p_pdf',item.folio, 4)" title="Descargar"><i
                                                    class="fa-solid fa-file-arrow-down"></i></button>
                                        </div>
                                    </ng-template>
                                    <ng-template #elseBlock>
                                        NA
                                    </ng-template>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</section>

<ejs-dialog id="dlgcarga" #dlgcarga header='Sat' width='800px' isModal='true' [visible]='false' showCloseIcon='true'
    [open]="">
    <ng-template #header>
        <div id="dlg-template" class="e-icon-settings"><i class="fa-solid fa-file"></i> Archivo a subir
        </div>
    </ng-template>
    <ng-template #content>
        <div class="callout callout-primary">
            <div id='droparea' style="height: 70px; background: #e9f5db; text-align: center; padding-top: 20px;">
                <a href="" id="browse" (click)='browseClick()'><u>BuscarArchivo</u></a>
                <ejs-uploader id="archivoCarga" allowedExtensions={{formato}} [autoUpload]='false'
                    [dropArea]='archivoArea' multiple='false' (selected)="selected($event)">
                    <ng-template #template let-data>
                        <span class="fileListwrapper">
                            <span class="icon template-icons sf-icon-{{data.type}}"></span>
                            <span class="name file-name">{{data.name}}
                                ({{data.size}}bytes)</span>
                            <span class="upload-status">{{data.status}}</span>
                        </span>
                        <span class="e-icons e-file-remove-btn" title="Remove"></span>
                    </ng-template>
                </ejs-uploader>
            </div>
        </div>
        <div class="callout callout-botones">
            <button type="button" class="btn btn-nuevo rounded-0 mb-2 mr-2" style="margin-top: 5px;"
                (click)="subirPdfXml()" title="Almacenar Archivo">
                Subir Archivo
            </button>
        </div>
    </ng-template>
</ejs-dialog>