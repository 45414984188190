import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { Grid, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CompraService } from './services/compra.service';
import { HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';

@Component({
    selector: 'app-compra',
    templateUrl: './compra.component.html',
    styleUrls: ['./compra.component.scss']
})
export class CompraComponent implements OnInit {
    public data: any;
    public encabezado: any;
    public fechaactual: Date = new Date();
    public ano_actual: any;
    public simbolo: String = "";
    @ViewChild('tabla') tabla: Grid;
    public initialPage: PageSettingsModel;
    public columns: Object[];
    public ClickedRow: any;

    public IdUrl: number = -1;
    public IdRow: number = -1;
    public AutorizoFactura: boolean = false;
    public AutorizoTimbrado: boolean = false;

    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private route: ActivatedRoute,
        private servicio: CompraService,
        public domSanitizer: DomSanitizer,
        private modalService: NgbModal
    ) {
        this.route.params.subscribe((params: Params) => {
            this.IdUrl = params.id;
        });

        this.route.data.subscribe((resp: { listado: any[] }) => {
            let datosResolver = JSON.parse(JSON.stringify(resp.listado)).respuesta;
            this.encabezado = datosResolver.encabezado;
            this.columns = datosResolver.detalle.columnas;
            this.data = datosResolver.detalle.detalle;
        });
    }

    ngOnInit(): void { }

    /**
     * Metodo para cambiar el estatus a CONFIRMADO por parte del proveedor
     * donde esta aceptando la orden de compra
     */
    public btnConfirmado() {
        this.blockUI.start('trabajando...');
        this.servicio.setEstatus(this.IdUrl, 17).subscribe((resp) => {
            if (resp.numero > 0) {
                this.servicio.getCompra(this.IdUrl).subscribe((resp) => {
                    this.encabezado = resp.respuesta.encabezado;
                });
                const modalRef = this.modalService.open(dlgMensaje);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            } else {
                this.servicio.getCompra(this.IdUrl).subscribe((resp) => {
                    this.encabezado = resp.respuesta.encabezado;
                });
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;

            }
        });
        this.blockUI.stop();
    }

    /**
     * Metodo para cambiar el estatus a RECHAZAR por parte del proveedor
     * donde esta rechanzado la orden de compra
     */
    public btnRechazar() {
        this.blockUI.start('trabajando...');
        this.servicio.setEstatus(this.IdUrl, 29).subscribe((resp) => {
            if (resp.numero > 0) {
                this.servicio.getCompra(this.IdUrl).subscribe((resp) => {
                    this.encabezado = resp.respuesta.encabezado;
                });
                const modalRef = this.modalService.open(dlgMensaje);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            } else {
                this.servicio.getCompra(this.IdUrl).subscribe((resp) => {
                    this.encabezado = resp.respuesta.encabezado;
                });
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            }

        });
        this.blockUI.stop();
    }

    /**
     * Metodo para generar la descarga de la factura del proveedor que se ha generado por medio del sistema
     */
    public getFacturaPDF(): void {
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/pdf" });
        this.servicio.getFacturaPDF(this.IdUrl).subscribe(
            response => {
                let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
                const url = window.URL.createObjectURL(blob);
                const element = document.createElement('a');
                element.href = window.URL.createObjectURL(blob);
                element.download = 'factura.pdf';
                element.click();
                this.blockUI.stop();
            }
        );
    }

    /**
     * Metodo para generar la descarga del archivo XML que corresponde a la factura del proveedor que se ha generado por medio del sistema
     */
    public getFacturaXML(): void {
        this.blockUI.start('trabajando...');
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        this.servicio.getFacturaXML(this.IdUrl).subscribe(
            response => {
                this.blockUI.stop();
                let byteArray = decodeURIComponent(escape(window.atob(response.archivo)));
                let blob: any = new Blob([byteArray], { type: 'application/xml' });
                const element = document.createElement('a');
                element.href = window.URL.createObjectURL(blob);
                element.download = 'factura.xml';
                element.click();
            }
        );
    }
}