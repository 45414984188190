import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { PageSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { SelectEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Dialog, DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from 'src/environments/environment';
import { comprasService } from '../compras/Services/compras.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PerfilCfdiService } from './Services/PerfilCfdi.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MdlCertificado } from './Models/MdlCertificado';
import { MdlSerie } from './Models/MdlSerie';
import { MdlProveedorPassword } from './Models/MdlProveedorPassword.model';
import { dlgMensaje } from 'src/app/components/dlgMensaje/dlgMensaje.component';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html',
    styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
    //********************************************************************** */
    // Variables Utilizadas
    //********************************************************************** */
    public _ID_Proveedor: number = -1;
    public _IDdocumento: number = -1;
    public _id_expediente: number = -1;
    public _id_tipo: number = 1;
    public _Tabla: string = '';
    public _Nombre: string = '';
    public IdRow: number = -1;
    public data: any;
    public datalistArchivos: any;
    UtilComponent: any;
    @BlockUI() blockUI: NgBlockUI;

    @ViewChild('dlgArchivo') public dlgArchivo: DialogComponent;
    @ViewChild('dlgExpediente') public dlgExpediente: DialogComponent;
    @ViewChild('dlgDocProveedorSolicitado') public dlgDocProveedorSolicitado: DialogComponent;
    //************************************************************************************************************************************ */
    //           GRID: Configuraciones                                                            */
    //************************************************************************************************************************************ */
    @ViewChild('dlgclave') public dlgclave: DialogComponent;
    @ViewChild('dlgfrmserie') public dlgfrmserie: DialogComponent;
    public _titulo: string = '';
    public formato: string = '';


    /*---------------------------------------------------------------------------------------------------*/
    /*- ---VARIABLES DEL FORMULARIO--- -]*                                                                        -*/
    /*------------------------------------------------------------------------------------------------------*/
    public formCertificado: UntypedFormGroup = new UntypedFormGroup({});
    public formserie: UntypedFormGroup = new UntypedFormGroup({});
    public type: Number = -1;

    public MdlCertificado = new MdlCertificado();
    public MdlSerie = new MdlSerie();
    //************************************************************************************************************************************ */
    //           GRID: Configuraciones                                                            */
    //************************************************************************************************************************************ */
    public initialPage: PageSettingsModel;
    public dataProveedor: any;
    public imagen: any;
    public toolbarOptions: ToolbarItems[];

    // variables de imagen
    private archivos: UploaderComponent;

    public archivoArea: HTMLElement;
    public dropEle: HTMLElement;
    public Id_Imagen: number = -1;
    public Id_Propiedades: number = -1;
    public correo: string = '';

    constructor(
        private modalService: NgbModal,
        private servicio: comprasService,
        private CFDI: PerfilCfdiService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private domSanitizer: DomSanitizer,
        private fb2: UntypedFormBuilder
    ) {
        this.route.data.subscribe((resp: { listado: any[] }) => {
            let datosResolver = JSON.parse(JSON.stringify(resp.listado)).respuesta;
            this.data = datosResolver.proveedor;
            if (this.data.logo) {
                this.imagen = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${this.data.logo}`);
            }
        });

        //************************************************************************************************************************************ */
        // form: instanciamos formulario de CSD
        //  this.formCertificado = this.fb2.group(this.MdlCertificado);
        this.formCertificado = this.fb2.group({
            id: (JSON.parse(localStorage.getItem('id'))),
            csd_clave: ['', [Validators.required, Validators.min(0)]],
        });
        this.formserie = this.fb2.group(this.MdlSerie);
        this.correo = localStorage.getItem('Correo');
    }
    ngOnInit(): void {
        //************************************************************************************************************************************ */
        // Grid: initialPage => Configuración configuramos a 7 Registros al grid
        this.initialPage = { pageSizes: true, pageSize: 7 };
        this.servicio.datos_proveedor().subscribe((resp) => {
            this.formserie = this.fb2.group(resp.respuesta.frmserie[0]);
        });
        /**
         * @service TbExpedientesProveedor 
         * @return Json este listado mostrará la tabla de archivos expedientes del proveedor
         */
        this.servicio.TbExpedientesProveedor().subscribe((resp) => {
            this.dataProveedor = resp.respuesta.dataExpedinetes;
        });
        /**
         * @service DocumentosSolicitados 
         * @return Json este listado mostrará tabla de archivos solicitados al proveedor
         */
        this.servicio.DocumentosSolicitados().subscribe((resp) => {
            this.datalistArchivos = resp.respuesta.datalistarchivos;
        });
    }
    public NuevoExpediente() {
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        this._Tabla = 'proveedor_imagen';
        this._id_expediente = 2;
        this._IDdocumento = -1;
        this.dlgExpediente.show();
    }

    public Guardar() {
    }

    //************************************************************************************************************************************ */
    public Msjeliminar(args) {
        this.IdRow = args.id;
        if (this.IdRow == -1) {
            this.UtilComponent = DialogUtility.confirm({
                title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>' + "No hay Documento Que Eliminar..." + '</b>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
        }
        else {
            this.UtilComponent = DialogUtility.confirm({
                title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>' + "Estas seguro de eliminar ?" + '</b>',
                okButton: { text: 'Si', click: this.BorrarExpediente.bind(this) },
                cancelButton: { text: 'No' },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
        }
    };

    //************************************************************************************************************************************ */
    public BorrarExpediente(componente: Dialog) {
        this.blockUI.start('trabajando...');
        this.servicio.borrarDocumento(Number(this.IdRow)).subscribe((resp) => {
            // recargamos despues de la eliminacion
            this.servicio.TbExpedientesProveedor().subscribe((resp) => {
                this.dataProveedor = resp.respuesta.dataExpedinetes;
            });
            this.UtilComponent.hide();
        });
        this.blockUI.stop();
        this.IdRow = -1;
    }

    //************************************************************************************************************************************ */
    public BorrarDocumentoSolicitado(componente: Dialog) {
        this.blockUI.start('trabajando...');
        this.servicio.borrarListado(Number(this.IdRow)).subscribe((resp) => {
            // recargamos despues de la eliminacion
            this.servicio.DocumentosSolicitados().subscribe((resp) => {
                this.datalistArchivos = resp.respuesta.datalistarchivos;
            });
            this.UtilComponent.hide();
        });
        this.blockUI.stop();
        this.IdRow = -1;
    }

    //************************************************************************************************************************************ */
    public MsjeliminarListados(args) {
        this.IdRow = args.id;
        if (this.IdRow < 0) {
            this.UtilComponent = DialogUtility.confirm({
                title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>' + "No hay Documento Que Eliminar..." + '</b>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
        }
        else {
            this.UtilComponent = DialogUtility.confirm({
                title: 'Información Arcarius' + '[Alerta]' + ' <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>' + "Estas seguro de eliminar ?" + '</b>',
                okButton: { text: 'Si', click: this.BorrarDocumentoSolicitado.bind(this) },
                cancelButton: { text: 'No' },
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
        }
    };

    //************************************************************************************************************************************ */
    public BorrarListados(componente: Dialog) {
        this.blockUI.start('trabajando...');
        this.servicio.borrarListado(Number(this.IdRow)).subscribe((resp) => {
            // recargamos despues de la eliminacion
            this.servicio.DocumentosSolicitados().subscribe((resp) => {
                this.dataProveedor = resp.respuesta.dataExpedinetes;
            });
            this.UtilComponent.hide();
        });
        this.blockUI.stop();
        this.IdRow = -1;
    }

    /***
     * imagen
     */
    //******navigators  */
    public handleSelectEvent(e: SelectEventArgs): void {
        if (e.selectedIndex === 2) {
            this.archivoArea = document.getElementById('droparea');
        }
    }

    // imagen
    public browseClick() {
        document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button').click(); return false;
    }

    public selected(args: UploaderComponent) {
        this.archivos = args;
    }

    public NuevoListDocumento(args: any) {
        this._id_tipo = -1;
        this._IDdocumento = args.id;
        if (args.id < 0) {
            this._id_tipo = args.idsss;
        }
        else {
            this._id_tipo = args.id;
        }
        this._id_expediente = -2;
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        this._Tabla = args.tabla;
        this.dlgDocProveedorSolicitado.show();
    }

    public ModificarExpediente(args: any) {
        this._IDdocumento = args.id;
        this._id_expediente = -2;
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        this._Tabla = args.tabla;
        this.dlgArchivo.show();
    }

    public subirExpedientes() {
        if (this.archivos == undefined) {
            DialogUtility.alert({
                title: 'Información Arcarius [Alerta] <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>No has seleccionado un archivo</b>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
            return;
        } else {
            //--=============================================================================================--=============================================================================================----=============================================================================================--
            //* SUBIMOS EL ARCHIVO NORMAL
            //--=============================================================================================--=============================================================================================----=============================================================================================--
            let formulario = new FormData();
            formulario.append('imagen', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
            formulario.append('Content-Type', 'application/json');
            formulario.append('Accept', `application/json`);
            this.blockUI.start('trabajando...');
            this.http.post(`${environment.base_url}/proveedor/perfil/subirExpediente/${this._IDdocumento}/${this.archivos.filesData[0].name}/${this._Tabla}/${this._id_expediente}/${this._id_tipo}`, formulario).subscribe(resp => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero > 0) {
                    //==============================================================================
                    (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                    //==============================================================================
                    this.servicio.TbExpedientesProveedor().subscribe((resp) => {
                        this.dataProveedor = resp.respuesta.dataExpedinetes;
                        this._id_expediente = -1;
                        this._id_tipo = -1
                    });
                    this.dlgExpediente.hide();
                    (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                    this._id_expediente = -1;
                    this._id_tipo = -1;
                    this.blockUI.stop();
                } else {
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title: 'Informacion Arcarius [Problemas]',
                        content: respuesta.mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                }
            });
        }
    }

    public subirDocumento() {
        if (this.archivos == undefined) {
            DialogUtility.alert({
                title: 'Información Arcarius [Alerta] <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>No has seleccionado un archivo</b>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
            return;
        } else {
            switch (this._id_tipo) {
                case 1:
                    //--=============================================================================================--=============================================================================================----=============================================================================================--
                    //* CARGAMOS EL KEY 
                    //--=============================================================================================--=============================================================================================----=============================================================================================--
                    let frmKEY = new FormData();
                    frmKEY.append('imagen', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
                    frmKEY.append('Content-Type', 'application/json');
                    frmKEY.append('Accept', `application/json`);
                    this.blockUI.start('trabajando...');
                    this.http.post(`${environment.base_url}/proveedor/perfil/SubirCertificado/${(JSON.parse(localStorage.getItem('id')))}/${this._id_tipo}`, frmKEY).subscribe(resp => {
                        let respuesta = JSON.parse(JSON.stringify(resp));
                        if (respuesta.numero > 0) {
                            (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                            //==============================================================================
                            this.servicio.datos_proveedor().subscribe((resp) => {
                                this.data = resp.respuesta.proveedor;
                                this.dataProveedor = resp.respuesta.dataExpedinetes;
                                this.datalistArchivos = resp.respuesta.datalistarchivos;
                                this._id_expediente = -1;
                                this._id_tipo = -1
                            });
                            this.dlgArchivo.hide();
                            (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                            this._id_expediente = -1;
                            this._id_tipo = -1;
                            this.blockUI.stop();
                        } else {
                            this.blockUI.stop();
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: respuesta.mensaje,
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                        }
                    });
                    break;
                case 2:
                    //--=============================================================================================--=============================================================================================----=============================================================================================--
                    //* CARGAMOS EL CER 
                    //--=============================================================================================--=============================================================================================----=============================================================================================--
                    let frmCER = new FormData();
                    frmCER.append('imagen', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
                    frmCER.append('Content-Type', 'application/json');
                    frmCER.append('Accept', `application/json`);
                    this.blockUI.start('trabajando...');
                    this.http.post(`${environment.base_url}/proveedor/perfil/SubirCertificado/${(JSON.parse(localStorage.getItem('id')))}/${this._id_tipo}`, frmCER).subscribe(resp => {
                        let respuesta = JSON.parse(JSON.stringify(resp));
                        if (respuesta.numero > 0) {
                            (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                            //==============================================================================
                            this.servicio.datos_proveedor().subscribe((resp) => {
                                this.data = resp.respuesta.proveedor;
                                this.dataProveedor = resp.respuesta.dataExpedinetes;
                                this.datalistArchivos = resp.respuesta.datalistarchivos;
                                this._id_expediente = -1;
                                this._id_tipo = -1;
                            });
                            this.dlgArchivo.hide();
                            (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                            this._id_expediente = -1;
                            this._id_tipo = -1;
                            this.blockUI.stop();
                        } else {
                            this.blockUI.stop();
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: respuesta.mensaje,
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                        }
                    });
                    break;
                case 5:
                    //--=============================================================================================--=============================================================================================----=============================================================================================--
                    //* CARGAMOS EL CER 
                    //--=============================================================================================--=============================================================================================----=============================================================================================--
                    let frmlogo = new FormData();
                    frmlogo.append('imagen', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
                    frmlogo.append('Content-Type', 'application/json');
                    frmlogo.append('Accept', `application/json`);
                    this.blockUI.start('trabajando...');
                    this.http.post(`${environment.base_url}/proveedor/perfil/subirLogo/${(JSON.parse(localStorage.getItem('id')))}`, frmlogo).subscribe(resp => {
                        let respuesta = JSON.parse(JSON.stringify(resp));
                        if (respuesta.numero > 0) {
                            (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                            //==============================================================================
                            this.servicio.datos_proveedor().subscribe((resp) => {
                                this.data = resp.respuesta.proveedor;
                                this.imagen = this.domSanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${this.data.logo}`);
                                this._id_expediente = -1;
                                this._id_tipo = -1;
                            });
                            this.dlgArchivo.hide();
                            (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                            this._id_expediente = -1;
                            this._id_tipo = -1;
                            this.blockUI.stop();
                        } else {
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: respuesta.respuesta,
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                        }
                    });
                    break;
                default:
                    DialogUtility.alert({
                        title: 'Informacion Arcarius [Problemas]',
                        content: 'No Cargo un Tipo Para Subir El Documento',
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                    break;
            }
        }
    }
    public DocProveedorSolicitados() {
        if (this.archivos == undefined) {
            DialogUtility.alert({
                title: 'Información Arcarius [Alerta] <i style="color: #FF0033" class="fas fa-exclamation"></i></span>',
                content: '<b>No has seleccionado un archivo</b>',
                showCloseIcon: true,
                closeOnEscape: true,
                animationSettings: { effect: 'Zoom' }
            });
            return;
        } else {
            //--=============================================================================================--=============================================================================================----=============================================================================================--
            //* SUBIMOS EL ARCHIVO NORMAL
            //--=============================================================================================--=============================================================================================----=============================================================================================--
            let formulario = new FormData();
            formulario.append('imagen', this.archivos.filesData[0].rawFile, this.archivos.filesData[0].name);
            formulario.append('Content-Type', 'application/json');
            formulario.append('Accept', `application/json`);
            this.blockUI.start('trabajando...');
            this.http.post(`${environment.base_url}/proveedor/perfil/subirExpediente/${this._IDdocumento}/${this.archivos.filesData[0].name}/${this._Tabla}/${this._id_expediente}/${this._id_tipo}`, formulario).subscribe(resp => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero > 0) {
                    //==============================================================================
                    (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                    //==============================================================================
                    this.servicio.DocumentosSolicitados().subscribe((resp) => {
                        this.datalistArchivos = resp.respuesta.datalistarchivos;
                        this._id_expediente = -1;
                        this._id_tipo = -1
                    });
                    this.dlgDocProveedorSolicitado.hide();
                    (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
                    this._id_expediente = -1;
                    this._id_tipo = -1;
                    this.blockUI.stop();
                } else {
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title: 'Informacion Arcarius [Problemas]',
                        content: respuesta.mensaje,
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' },
                    });
                }
            });
        }
    }

    private _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    /**
     * 
     * @param args 
     */
    public onMostrarPdf(args: any): void {
        let nombrearchivo: string;
        nombrearchivo = args.nombre_archivo;
        // ================================================================================
        // Ejecutamos el reporte con los parametros proporcionados
        // ================================================================================
        // this.blockUI.start('trabajando...');
        let resonseType: any = 'blob';
        let headers: HttpHeaders = new HttpHeaders({ "Content-Type": "application/json" });
        this.http.post<any>(`${environment.base_url}/proveedor/perfil/descargarDoc/${args.id}/${'imagen'}/${args.tabla}`, {})
            .subscribe(
                response => {
                    if (response.numero > 0) {
                        // ==========================================================================
                        // decodificacion => decodificamos el json que recibimos en response
                        // la funcion es "decodeURIComponent" la traemos con el codificado atob
                        // simplemente agregamos decodeURIComponent para devolver el pdf sin caracteres especiales
                        // respetando la codificacion UTF - 8
                        // ==========================================================================
                        let byteArray = this._base64ToArrayBuffer(response.archivo);
                        // Tipo se coloca =>  "application/xml": para reconocerlo como archivo pdf archivo de salida
                        let blob: any = new Blob([byteArray], { type: 'application/pdf; charset=utf-8' });
                        /**
                        * agregamos este pequeño codigo para descargar el xml
                        */
                        const element = document.createElement('a');
                        element.href = window.URL.createObjectURL(blob);
                        element.download = nombrearchivo;//response.nombre_archivo;//this.dataInfo.folio;
                        element.click();
                        // const url = window.URL.createObjectURL(blob);
                        // window.open(url, '_blank');
                        this.blockUI.stop();
                    } else {
                        DialogUtility.alert({
                            title: response.titulo,
                            content: response.mensaje,
                            showCloseIcon: true,
                            closeOnEscape: true,
                            animationSettings: { effect: 'Zoom' },
                        });
                    }
                },
                err => {
                    this.blockUI.stop();
                    console.log(err);
                    let error: number = err.status;
                    switch (error) {
                        case 500:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error tu archivo aun no existe, favor de revisar con Recursos Humanos',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        case 404:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a Dpt Sistemas',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                        default:
                            DialogUtility.alert({
                                title: 'Informacion Arcarius [Problemas]',
                                content: 'Error de Sistema, Reporte a RH',
                                showCloseIcon: true,
                                closeOnEscape: true,
                                animationSettings: { effect: 'Zoom' },
                            });
                            break;
                    }
                    this.blockUI.stop();
                }
            );
    }

    //--=============================================================================================--=============================================================================================----=============================================================================================--
    //? ALMACENMOS EL NUMERO DE CERTIFICADO Y SU VIGENCIA
    //--=============================================================================================--=============================================================================================----=============================================================================================--
    public AlmacenarPass() {
        // mandamos llamar el servicio para cambiar la contraseña o clave del certificado
        this.formCertificado.controls['id'].setValue(JSON.parse(localStorage.getItem('id')));
        this.blockUI.start('trabajando...');
        this.CFDI.GuardarCSD(this.formCertificado.value).subscribe(
            resp => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero < 0) {
                    this.blockUI.stop();
                    this.dlgclave.hide();
                    DialogUtility.alert({
                        title: '<span class="dialogo-titulo-error">' + respuesta.titulo + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                        content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' }
                    });
                }
                else {
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title: respuesta.titulo + '<span style="color: #28a745">' + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                        content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' }
                    });
                    this.dlgclave.hide();
                    //****************************************************************** */
                    // Listados: instanciamos los listados
                    this.servicio.datos_proveedor().subscribe((resp) => {
                        let datosResolver = JSON.parse(JSON.stringify(resp)).respuesta;
                        this.datalistArchivos = datosResolver.datalistarchivos;
                    });
                    this.formCertificado = this.fb2.group({
                        id: (JSON.parse(localStorage.getItem('id'))),
                        csd_clave: ['', [Validators.required, Validators.min(0)]],
                    });
                }
            }
        );
    }

    //--=============================================================================================--=============================================================================================----=============================================================================================--
    //         * SUBIDA DE ARCHIVOS PARA CERTIFICADOS Y CONTRASEÑAS  DE IGUAL MANERA LOS PEMS*
    //--=============================================================================================--=============================================================================================----=============================================================================================--

    //--=============================================================================================--=============================================================================================----=============================================================================================--
    //? GESTIÓN CERTIFICADO  
    //--=============================================================================================--=============================================================================================----=============================================================================================--
    public NumCertificado() {
        this.dlgclave.show();
    }
    //--=============================================================================================--=============================================================================================----=============================================================================================--
    //? GESTIÓN KEY Y CER  
    //--=============================================================================================--=============================================================================================----=============================================================================================--

    public AgregarKEY() {
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        this._id_tipo = 1;
        this.formato = '.key';
        this.dlgArchivo.show();
    }

    public AgregarCER() {
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        this._id_tipo = 2;
        this.formato = '.cer';
        this.dlgArchivo.show();
    }
    //--=============================================================================================--=============================================================================================----=============================================================================================--
    //? LOGO  
    //--=============================================================================================--=============================================================================================----=============================================================================================--

    public CambiarLogo() {
        (document.getElementById('archivoCarga') as any).ej2_instances[0].clearAll();
        this._id_tipo = 5;
        this.dlgArchivo.show();
    }
    //--=============================================================================================--=============================================================================================----=============================================================================================--
    //? CONFIGURACIÓN DEL SERIE DE VENTA  
    //--=============================================================================================--=============================================================================================----=============================================================================================--

    public FRMserie(_id_: Number) {
        switch (_id_) {
            case 1:
                this.type = _id_;
                this.dlgfrmserie.show();
                break;
            case 2:
                this.type = _id_;
                this.dlgfrmserie.show();
                break;
            case 3:
                this.type = _id_;
                this.dlgfrmserie.show();
                break;
            case 4:
                this.type = _id_;
                this.dlgfrmserie.show();
                break;
            case 5:
                this.type = _id_;
                this.dlgfrmserie.show();
                break;
            case 6:
                this.type = _id_;
                this.dlgfrmserie.show();
                break;
            default:
                DialogUtility.alert({
                    title: 'Informacion Arcarius [Problemas]',
                    content: 'Error de Sistema, Reporte a Dpt Sistemas',
                    showCloseIcon: true,
                    closeOnEscape: true,
                    animationSettings: { effect: 'Zoom' },
                });
                break;
        }
    }

    public guardarSerie() {
        this.type = -1;
        this.dlgfrmserie.hide();
        this.blockUI.start('trabajando...');
        this.CFDI.GuardarSerie(this.formserie.value).subscribe(
            resp => {
                let respuesta = JSON.parse(JSON.stringify(resp));
                if (respuesta.numero < 0) {
                    DialogUtility.alert({
                        title: respuesta.titulo + '<span class="dialogo-titulo-error">' + ' <i style="color: #fff" class="fas fa-ban"></i></span>',
                        content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' }
                    });
                }
                else {
                    this.servicio.datos_proveedor().subscribe(resp => {
                        this.data = JSON.parse(JSON.stringify(resp)).respuesta.proveedor;
                        this.formserie = this.fb2.group(resp.respuesta.frmserie[0]);
                    });
                    this.blockUI.stop();
                    DialogUtility.alert({
                        title: respuesta.titulo + '<span style="color: #28a745">' + ' <i style="color: #28a745" class="fas fa-check-double"></i></span>',
                        content: '<b>' + JSON.parse(JSON.stringify(resp)).mensaje + '</b>',
                        showCloseIcon: true,
                        closeOnEscape: true,
                        animationSettings: { effect: 'Zoom' }
                    });
                }
            }
        );
    }

    /**
     * Metodo para cambiar el password del proveedor
     */
    public btnPassword() {
        let password = prompt("Nueva Contraseña", "");
        if (password == null) {
            return;
        }
        this.blockUI.start('trabajando...');
        let modelo: MdlProveedorPassword = new MdlProveedorPassword();
        modelo.id = Number(localStorage.getItem('id'));
        modelo.password = password;
        this.servicio.setPassword(modelo).subscribe(resp => {
            let respuesta = JSON.parse(JSON.stringify(resp));
            if (respuesta.numero > 0) {
                const modalRef = this.modalService.open(dlgMensaje);
                modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(resp)).titulo;
                modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                this.blockUI.stop();
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = JSON.parse(JSON.stringify(respuesta)).titulo;
                modalRef.componentInstance.contenido = JSON.parse(JSON.stringify(respuesta)).mensaje;
                this.blockUI.stop();
            }
        });
    }

}
