import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CotizacionesService } from './cotizaciones.service';

@Injectable({
  providedIn: 'root'
})
export class CotizacionesResolver implements Resolve<boolean> {

  errores: any;
  constructor(
    private service: CotizacionesService

  ) { }
  
  /**
   * 
   * @param route 
   * @param state 
   * @returns retornamos los valores de las cotizaciones
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> | any {
    return this.service.Tbcotizaciones()
    .pipe(
      catchError(error => {
        return throwError(this.errores.getErrores(error));
      })
    );
  }


}
