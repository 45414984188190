<div class="container">
    <div class="row">
        <div class="col-md-1">
            <img src="../../assets/img/logo-icon.png" style="width: 50px;" alt="">
        </div>
        <div class="col-md-9" style="color: #f44336; font-size: 1.5rem;">
            {{this.empresa}}
        </div>
    </div>
</div>
<div id="menuHolder">
    <div role="navigation" class="sticky-top border-bottom border-top" id="mainNavigation">
        <div class="flexMain">
            <div class="flex2">
                <button class="whiteLink siteLink" style="border-right:1px solid #eaeaea" onclick="menuToggle()">
                    <i class="fas fa-bars me-2"></i> MENU
                </button>
            </div>
            <div class="flex3 text-center" id="siteBrand">
                Arcarius ERP - Proveedores
            </div>

            <div class="flex2 text-end d-none d-md-block">
                <button class="siteLink" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Salir</button>
            </div>
        </div>
    </div>

    <div id="menuDrawer">
        <div class="p-4 border-bottom">
            <div class='row'>
                <div class="col text-end ">
                    <i class="fas fa-times" role="btn" onclick="menuToggle()"></i>
                </div>
            </div>
        </div>
        <div>
            <a [routerLink]="['index']" class="nav-menu-item" onclick="menuToggle()"><i class="fas fa-home"></i> Inicio</a>
            <a [routerLink]="['cotizaciones']" class="nav-menu-item" onclick="menuToggle()"><i class="fa-solid fa-cart-shopping"></i> Cotizaciones</a>
            <a [routerLink]="['compras']" class="nav-menu-item" onclick="menuToggle()"><i class="fa-solid fa-truck-arrow-right"></i> Compras</a>
            <a [routerLink]="['productos']" class="nav-menu-item" onclick="menuToggle()"><i class="fa-solid fa-box-open"></i> Productos</a>
            <a [routerLink]="['perfil']" class="nav-menu-item" onclick="menuToggle()"><i class="fas fa-user"> </i> Perfil</a>
            <a [routerLink]="['about']" class="nav-menu-item" onclick="menuToggle()"><i class="fas fa-building me-3"></i>Nuestra Empresa</a>
        </div>
    </div>
</div>