<section>
    <div class="row mt-4">
        <div class="col-12 col-lg-12">
            <div class="row">
                <div class="col-sm-6">
                    <div>
                        <span class="text-sm text-grey-m2 align-middle"><strong>Proveedor</strong></span>
                    </div>
                    <div class="text-grey-m2">
                        <div style="font-size: 18px;">
                            {{this.encabezado.nombre}}
                        </div>
                        <div>
                            RFC: {{this.encabezado.rfc}}
                        </div>
                        <div>
                            CP: {{this.encabezado.cp}}
                        </div>
                        <div>
                            Dirección: {{this.encabezado.proveedordireccion}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="row">
                        <button *ngIf="!this.encabezado.bloqueo" class="btn btn-outline-success"
                            (click)="btnConfirmado()">CONFIRMADO</button>
                        <button *ngIf="!this.encabezado.bloqueo" class="btn btn-outline-danger"
                            (click)="btnRechazar()">RECHAZAR</button>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <strong>Orden de Compra</strong>
                            <div>Folio: {{this.encabezado.folio}} </div>
                            <div>Fecha: {{this.encabezado.fecha | date:'dd/MM/yyyy'}}
                            </div>
                            <div>Entrega: {{this.encabezado.fecha_entrega | date:'dd/MM/yyyy'}}
                            </div>
                            <div>
                                Estatus: {{this.encabezado.estatus}}
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <strong>Terminos y Condiciones</strong>
                            <div>Moneda: {{this.encabezado.moneda}}
                            </div>
                            <div>Dias Crédito: {{this.encabezado.dias}}
                            </div>
                            <div>Referencia: {{this.encabezado.referencia}}
                            </div>
                            <div>Condiciones: {{this.encabezado.condicionespago}}
                            </div>
                            <div>Observaciones: {{this.encabezado.observaciones}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top: 10px;">
                <ejs-grid #tabla [dataSource]='data' allowSorting='true' allowResizing='true'>
                    <e-columns>
                        <ng-template #template ngFor let-column [ngForOf]="columns">
                            <e-column *ngIf="column.nombre.toUpperCase().startsWith('ID')" [field]="column.nombre"
                                width="0">
                            </e-column>
                            <e-column
                                *ngIf="!column.nombre.toUpperCase().startsWith('ID') && column.nombre !== 'Descripcion'"
                                [field]="column.nombre" minWidth="100" width='130' format="n2"
                                [textAlign]="column.tipo === 'numeric' ? 'right': 'left'">
                            </e-column>
                            <e-column *ngIf="column.nombre.toUpperCase().startsWith('DESCRIPCION')"
                                [field]="column.nombre" minWidth="200">
                                <ng-template #template let-item>
                                    <div [innerHTML]="item.Descripcion | safeHtml"></div>
                                </ng-template>
                            </e-column>
                        </ng-template>
                    </e-columns>
                </ejs-grid>

            </div>
            <div class="row">
                <div class="col-md-9">
                </div>
                <div class="col-md-3 justify-content-end">
                    <table class="float-right">
                        <tr>
                            <td style="text-align: left; width: 100px;">SubTotal</td>
                            <td style="text-align: right; width: 100px;">{{this.encabezado.subtotal | number: '.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <td>Impuesto</td>
                            <td style="text-align: right;">{{this.encabezado.impuesto | number: '.2-2'}}</td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td style="text-align: right;">{{this.encabezado.total | number: '.2-2'}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <p>Nuestro software <strong>Arcarius ERP</strong> puede generar y timbrar la factura por ti.</p>
                <p>Solo es necesario autorizar el proceso de generación y timbrado, aceptando los siguientes
                    condiciones:</p>
                <p>
                    Por este medio autorizo a Arcarius ERP para generar la factura correspondiente a esta orden de
                    compra.
                </p>
                <div class="col-md-4">
                    <input type="checkbox" class="btn-check" id="btnAutoriza" autocomplete="off"
                        [(ngModel)]="AutorizoFactura">
                    <label class="btn btn-outline-primary" for="btnAutoriza">
                        Autorizo
                        <i *ngIf="!AutorizoFactura" class="fa-regular fa-square"></i>
                        <i *ngIf="AutorizoFactura" class="fa-regular fa-square-check"></i>
                    </label>
                </div>
                <p>
                    Por este medio autorizo a Arcarius ERP para timbrar la factura correspondiente a esta orden de
                    compra, por medio del Certificado de Sello Digital que proporcione al sistema con el fin de hacer el
                    timbrado correspondiente.
                </p>
                <div class="col-md-4">
                    <input type="checkbox" class="btn-check" id="btnTimbra" autocomplete="off"
                        [(ngModel)]="AutorizoTimbrado">
                    <label class="btn btn-outline-primary" for="btnTimbra">
                        Autorizo Timbrado
                        <i *ngIf="!AutorizoTimbrado" class="fa-regular fa-square"></i>
                        <i *ngIf="AutorizoTimbrado" class="fa-regular fa-square-check"></i>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <p>
                        El procceso de timbrado lo realiza <strong>Arcarius ERP</strong> y será responsabilidad del
                        proveedor descargar el XML y PDF para ser incluido en su contabilidad
                    </p>
                    <button *ngIf="this.AutorizoFactura && this.AutorizoTimbrado" class="btn btn-procesar"
                        (click)="btnConfirmado()">Generar y Timbrar Factura</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <button class="btn btn-primary" (click)="getFacturaPDF()">
                        Factura PDF
                        <i class="fa-solid fa-file-arrow-down"></i>
                    </button>
                    <button class="btn btn-primary" (click)="getFacturaXML()">
                        Factura XML
                        <i class="fa-solid fa-file-arrow-down"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>