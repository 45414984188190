import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule, ToolbarModule, ContextMenuModule, TabAllModule, TreeViewModule, SidebarModule, MenuModule, TreeViewAllModule, } from '@syncfusion/ej2-angular-navigations';
import { ComboBoxModule, AutoCompleteModule, MultiSelectModule, ListBoxModule, DropDownTreeModule, DropDownListAllModule, } from '@syncfusion/ej2-angular-dropdowns';
import { enableRipple } from '@syncfusion/ej2-base';
import {
    AccumulationChartModule,
    RangeNavigatorModule,
    SparklineModule,
    SmithchartModule,
    StockChartModule,
    BulletChartModule,
    CategoryService,
    LineSeriesService,
    BarSeriesService,
    DataLabelService,
    TooltipService,
    LegendService,
    ColumnSeriesService,
    ChartAnnotationService,
    RangeColumnSeriesService,
    StackingColumnSeriesService,
    SelectionService,
    ChartAllModule,
    RangeNavigatorAllModule,
    AccumulationChartAllModule
} from '@syncfusion/ej2-angular-charts';
import { DialogModule, TooltipModule } from '@syncfusion/ej2-angular-popups';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { EditService, FilterService, GridAllModule, PagerModule, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { TextBoxModule, TextBoxAllModule, NumericTextBoxAllModule, SliderAllModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs';
import { ChartComponent } from '@syncfusion/ej2-angular-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckBoxAllModule, ChipListAllModule, RadioButtonAllModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { MainPipesModule } from '../pipes/main-pipes.module';
import { BlockUIModule } from 'ng-block-ui';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
enableRipple(true);

@NgModule({
    declarations: [

    ],
    imports: [
        BlockUIModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule,
        ToolbarModule,
        ContextMenuModule,
        TabAllModule,
        TreeViewModule,
        TreeViewAllModule,
        SidebarModule,
        MenuModule,
        DropDownListAllModule,
        ComboBoxModule,
        AutoCompleteModule,
        MultiSelectModule,
        ListBoxModule,
        DropDownTreeModule,
        ChartAllModule,
        RangeNavigatorAllModule,
        AccumulationChartAllModule,
        AccumulationChartModule,
        RangeNavigatorModule,
        SparklineModule,
        SmithchartModule,
        StockChartModule,
        BulletChartModule,
        DialogModule,
        TextBoxModule,
        TextBoxAllModule,
        TooltipModule,
        GridAllModule,
        DatePickerAllModule,
        PagerModule,
        ListViewModule,
        ChipListAllModule,
        SwitchAllModule,
        NumericTextBoxAllModule,
        CheckBoxAllModule,
        RadioButtonAllModule,
        SliderAllModule,
        MainPipesModule,
        UploaderAllModule,
        RxReactiveFormsModule,
        NgbModule,
        NgbNavModule
    ],
    exports: [
        RxReactiveFormsModule,
        BlockUIModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule,
        ToolbarModule,
        ContextMenuModule,
        TabAllModule,
        TreeViewModule,
        SidebarModule,
        MenuModule,
        DropDownListAllModule,
        ComboBoxModule,
        AutoCompleteModule,
        MultiSelectModule,
        ListBoxModule,
        DropDownTreeModule,
        ChartAllModule,
        RangeNavigatorAllModule,
        AccumulationChartAllModule,
        AccumulationChartModule,
        RangeNavigatorModule,
        SparklineModule,
        SmithchartModule,
        StockChartModule,
        BulletChartModule,
        DialogModule,
        TextBoxModule,
        TextBoxAllModule,
        TooltipModule,
        GridAllModule,
        DatePickerAllModule,
        PagerModule,
        ListViewModule,
        ChipListAllModule,
        SwitchAllModule,
        NumericTextBoxAllModule,
        CheckBoxAllModule,
        RadioButtonAllModule,
        SliderAllModule,
        MainPipesModule,
        UploaderAllModule,
        NgbModule,
        NgbNavModule
    ],
    providers: [
        ToolbarService,
        FilterService,
        CategoryService,
        LineSeriesService,
        BarSeriesService,
        DataLabelService,
        TooltipService,
        LegendService,
        ChartComponent,
        ColumnSeriesService,
        ChartAnnotationService,
        RangeColumnSeriesService,
        StackingColumnSeriesService,
        EditService,
        SelectionService
    ],
    // ===============================================================================================
    // La siguiente linea remueve los errores de sintaxis del los componentes web, en los archivos
    // html de cada componente. Esto para los componentes importados de terceros SyncFusion.
    // ===============================================================================================
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
    // ===============================================================================================
})
export class SharedModule { }
