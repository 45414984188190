import { Component, OnInit, ViewChild } from '@angular/core';
import { PageSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ProductoService } from './Services/Producto.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';
import { PrecioComponent } from './components/precio/precio.component';
import { MdlPrecio } from './Models/MdlPrecio';

@Component({
    selector: 'producto',
    templateUrl: './producto.component.html',
    styleUrls: ['./producto.component.scss']
})
export class ProductoComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;
    public initialPage: PageSettingsModel;
    public data: any;
    public tb_productos: any;
    public configura: any;
    public IdUrl: number = -1;

    constructor(
        private servicio: ProductoService,
        private route: ActivatedRoute,
        private modalService: NgbModal
    ) {
        this.route.data.subscribe((resp: { listado: any[] }) => {
            let datosResolver = JSON.parse(JSON.stringify(resp.listado)).respuesta;
            this.tb_productos = datosResolver.data;
            this.configura = datosResolver.configura;

        });
    }

    ngOnInit(): void {

    }

    /**
     * Metodo para editar y cambiar el precio del producto seleccionado
     * @param args 
     */
    public btnEditar(args: any) {
        let modelo: MdlPrecio = new MdlPrecio();
        modelo.id = Number(args.id);
        modelo.precio = Number(args.precio);
        const modalRef = this.modalService.open(PrecioComponent);
        modalRef.componentInstance.precio = modelo.precio;
        modalRef.componentInstance.respuesta.subscribe(resp => {
            // El componente modal devolvera el precio capturado por el usuario y este
            // sera enviado a la API para su registro
            if (resp > 0) {
                modelo.precio = resp;
                this.servicio.GuardarPrecio(modelo).subscribe(resp => {
                    if (JSON.parse(JSON.stringify(resp)).numero > 0) {
                        this.servicio.cargaProducto().subscribe(resp => {
                            this.tb_productos = resp.respuesta.data;
                        });
                    } else {
                        const modalRef = this.modalService.open(dlgMensajeError);
                        modalRef.componentInstance.encabezado = resp.titulo;
                        modalRef.componentInstance.contenido = resp.mensaje;
                    }
                });
            }
        });
    }

}
