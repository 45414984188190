import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../auth/services/usuario.service';
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	public menu: { [key: string]: Object }[];
	public NotificacionPosicion: number = 1;
	public notificacion: any = [];
	public empresa: string;
	public myScriptElement: HTMLScriptElement;

	constructor(
		private usuarioService: UsuarioService
	) {
		this.empresa = localStorage.getItem('empresa');
		this.myScriptElement = document.createElement("script");
		this.myScriptElement.src = "assets/js/menu.js";
		document.body.appendChild(this.myScriptElement);
	}

	ngOnInit() { }

	logout(): void {
		this.usuarioService.logout();
	}

}
