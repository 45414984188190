import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MdlPrecio } from '../../Models/MdlPrecio';

@Component({
    selector: 'app-precio',
    templateUrl: './precio.component.html',
    styleUrls: ['./precio.component.scss']
})
export class PrecioComponent {

    @ViewChild('precio') tfPrecio: ElementRef;
    @Input('precio') precio: number;
    @Output('respuesta') respuesta = new EventEmitter<number>();

    public formprecio: UntypedFormGroup = new UntypedFormGroup({});

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal
    ) {
        this.formprecio = this.fb.group(new MdlPrecio);
    }

    ngAfterInit(): void {
        this.tfPrecio.nativeElement.value = this.precio;
    }

    /**
     * Metodo a ejecutar cuando el usuario da clic en el boton de Aceptar
     */
    public btnAceptar(){
        this.setRespuesta(this.tfPrecio.nativeElement.value);
    }

    /**
     * Metodo para devolver la respuesta del cuadro de dialogo
     * @param id 
     */
    public setRespuesta(resp: number) {
        this.modalService.dismissAll();
        this.respuesta.emit(resp);
    }

}
